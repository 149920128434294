import { camelizeKeys } from 'humps';

import { AuthorizationCodeResponse } from 'src/api/authTypes';
import {
  CountryCode,
  Order,
  OrderSource,
  OrderType,
  ProcessingSystem,
  Status,
  StatusHistoryEntry,
} from 'src/api/orderTypes';
import { initialCHOrderEntryState } from 'src/CHOrderEntry/redux/chOrderEntrySlice';
import { DEFAULT_SALES_CHANNEL, DEFAULT_SALES_OFFICE } from 'src/config/salesChannels';
import { RootStateType } from 'src/constants/types';
import { EditedOrderResponse } from 'src/editedorder/types/EditedOrderResponse';
import { EditedOrderStatusTypes } from 'src/editedorder/types/EditedOrderStatus';
import { orderPreCheckInitialState } from 'src/orderPreCheck/redux/orderPreCheckSlice';
import { LanguageOptions } from 'src/redux/app/appSlice';
import { botRequestInitialState } from 'src/redux/bot/botRequestSlice';
import { customerPinInitialState } from 'src/redux/customer/CustomerPinSlice';
import { waitlistHistoryInitialState } from 'src/redux/customer/waitlistHistorySlice';
import { editOrderInitialState } from 'src/redux/editOrder/editOrderSlice';
import { inflightOrderInitialState } from 'src/redux/inflightOrder/inflightOrderSlice';
import { ReklaOrder } from 'src/redux/order/orderEntrySlice';
import {
  DEFAULT_PAYMENTS,
  InstallmentFactoringModalMode,
  InstallmentFactoringModalSteps,
  InstallmentModalSteps,
} from 'src/redux/payment/paymentSlice';
import { recreateOrderInitialState } from 'src/redux/recreateOrder/recreateOrderSlice';
import { subscriptionManagementInitialState } from 'src/subscriptionProductManagement/redux/subscriptionManagementSlice';
import { BotRequestInfo, BotRequestReason } from 'src/types/bot/BotRequest';
import {
  AddressType,
  DeliveryAddressType,
  PostalDeliveryAddress
} from 'src/types/customer/address';
import {
  Customer,
  CustomerConsentBooleanFlags,
  DataProtectionBooleanFlags
} from 'src/types/customer/customer';
import { CustomerResponse } from 'src/types/customer/CustomerResponse';
import { CustomerSearchRequest } from 'src/types/customer/CustomerSearchRequest';
import { OrderRestriction } from 'src/types/customer/OrderRestriction';
import { ReceptionProvider } from 'src/types/customer/ReceptionSurveyQuestionsData';
import { Salutation } from 'src/types/customer/Salutation';
import { InflightOrder } from 'src/types/inflightOrder/InflightOrder';
import { Steps } from 'src/types/inflightOrder/InflightOrderRequest';
import { DimensionKind, StockLevel } from 'src/types/offer/Basket';
import { BasketResponse } from 'src/types/offer/BasketResponse';
import { CreateOrderResultResponse } from 'src/types/offer/CreateOrderResultResponse';
import { DeliveryMethod } from 'src/types/offer/DeliveryMethod';
import { GuestOrderRequest } from 'src/types/offer/GuestOrderRequest';
import { ItemSource } from 'src/types/offer/ItemSource';
import { OfferItem } from 'src/types/offer/Offer';
import { OfferResponse } from 'src/types/offer/OfferResponse';
import { OrderItem } from 'src/types/offer/OrderItem';
import { OrderPreCheckResponse } from 'src/types/offer/OrderPreCheck';
import { OrderResponse } from 'src/types/offer/OrderResponse';
import { BankingDetailsState } from 'src/types/offer/Payment';
import { PaymentMethod } from 'src/types/offer/PaymentMethod';
import { PaymentOption } from 'src/types/offer/PaymentOption';
import { SourceChannels } from 'src/types/offer/SalesSource';
import { EditedOffer, EditedOrder } from 'src/types/orderhistory/EditedOrder';
import {
  OrderHistoryResponse,
  OrderPayment,
  OrderStatusEnum
} from 'src/types/orderhistory/OrderHistoryResponse';
import { CrossSellType } from 'src/types/product/CrossSell';
import { Currency } from 'src/types/product/Currency';
import { DimensionType } from 'src/types/product/dimensions';
import {
  Product,
  ProductDetails,
  ProductDetailsViews,
  ProductImpressionsViewItem,
  ProductSearchResponse,
  ProductStatus
} from 'src/types/product/product';
import { ProductVariant } from 'src/types/product/SearchResponse';
import { SearchSuggestionResponse } from 'src/types/product/SearchSuggestionResponse';
import { SuggestionType } from 'src/types/product/SuggestionsResponse';
import { StockReminderRequest } from 'src/types/stockReminder/StockReminderRequest';
import { SubscriptionItem, SubscriptionItemStatusType } from 'src/types/subscription/SubscriptionHistoryResponse';
import { SubscriptionRotation } from 'src/types/subscription/SubscriptionRequest';
import { SubscriptionStatusRequest } from 'src/types/subscription/SubscriptionsOverviewRequest';
import { CustomerType } from 'src/types/voucher/EmployeeDiscount';
import { RedeemedVouchersResponse } from 'src/types/voucher/RedeemedVoucherResponse';
import { ServiceVoucher } from 'src/types/voucher/ServiceVoucher';
import { VoucherSuggestionsResponse } from 'src/types/voucher/SuggestedVoucherResponse';
import { VoucherRestrictionMode } from 'src/types/voucher/VirtualVoucherRequest';
import {
  VirtualVoucherType,
  VoucherRestrictionType,
  VoucherShortType
} from 'src/types/voucher/VirtualVoucherType';
import { ProgressiveDiscount, ProgressiveDiscountInfo } from 'src/types/voucher/VoucherResponse';
import { WaitlistHistoryItemsResponse, WaitlistItemStatusType } from 'src/types/waitlist/WaitlistHistoryResponse';

import { NormalizedProductDetail } from './formatters/normalizeProductDetails';
import { SavedCustomer } from './localCustomerStorage';
import { previewLetterStateInitialState } from '../previewLetter/redux/proviewLetterSlice';
import { notificationsTickerInitialState } from '../redux/app/notificationsTickerSlice';
import { subscriptionReportInitialState } from '../subscriptionProductManagement/redux/subscriptionReportSlice';
import {
  SubscriptionChangeEventType,
  SubscriptionChangeItem,
  SubscriptionChangesHistoryResponse
} from '../subscriptionProductManagement/types/response/SubscriptionChangesHistoryResponse';
import { SubscriptionReportResponse } from '../subscriptionProductManagement/types/response/SubscriptionReportResponse';
import { SubscriptionsOverviewResponse } from '../types/subscription/SubscriptionsOverviewResponse';


export const PostalDeliveryAddressExample: PostalDeliveryAddress = {
  id: '9c8a9e6d-5a59-4c26-b916-baf70f4b967c',
  type: DeliveryAddressType.Postal,
  salutation: Salutation.miss,
  firstName: 'Foo',
  lastName: 'Bar',
  street: 'baz',
  streetNumber: 'bat',
  zipCode: '12345',
  countryCode: CountryCode.de,
  city: 'Berlin',
};

export const testOrderItem: OrderItem = {
  mediaUris: [],
  basketId: '8edbed41-5cea-4cd8-9285-d6a8dd4b33ad',
  baseProductNo: '428474',
  brand: {
    brandName: '',
  },
  name: { long: 'long product name' },
  stockLevel: StockLevel.almostSoldOut,
  variant: {
    price: {
      currencyCode: Currency.euro,
      value: 12,
    },
    sku: '428474001',
    dimensions: [],
    images: [],
  },
  quantity: 1,
  id: '121212323',
  isSellable: true,
  status: {
    type: 'SELLABLE',
  },
  reserved: true,
  availableStockAmount: 6,
  crossSell: {
    id: 123,
    productNo: 'no',
    type: CrossSellType.STUNDE,
    sourceType: 'CROSS_SELL',
  },
};

export const testItemResponse: OfferItem = {
  createdAt: '2022-12-27T10:15:37Z',
  id: '8edbed41-5cea-4cd8-9285-d6a8dd4b33ad',
  baseProductNo: '123456',
  categoryPath: 'category',
  voucherDiscountValue: 1,
  crossSell: {
    id: 123,
    productNo: 'no',
    type: CrossSellType.STUNDE,
    sourceType: 'CROSS_SELL',
  },
  name: {
    long: 'product',
  },
  quantity: 2,
  variant: {
    sku: 'SKU123',
    price: {
      value: 50,
      label: 'label',
    },
    dimensions: [{
      type: DimensionKind.alloy,
      value: 'value',
    }],
  },
  brand: {
    brandName: 'brandName',
  },
  salesdrivers: [{
    typeCode: 'typeCode',
    name: 'name',
  }],
  stockLevel: StockLevel.available,
  mediaUris: [],
};

export const testCustomerResponse = {
  id: '4473b2e9-ea87-4f96-a3b0-1f91e5d593ff',
  first_name: 'Bill',
  last_name: 'Shatner',
  salutation: 'MR',
  date_of_birth: '1940-07-13',
  email: 'bill@shatner.com',
  billing_address: {
    id: 'de31d1f2-3003-466a-bccb-91aeda396ec8',
    street: 'Via Dolorosa',
    street_number: '1',
    zip_code: '666',
    city: 'Jerusalem',
    country_code: 'DE',
  },
  delivery_addresses: [
    {
      id: '440cd3d5-3fe3-43c3-85b5-8c6757bcb536',
      first_name: 'William',
      last_name: 'Shatner',
      salutation: 'MR',
      street: 'VIP Street',
      street_number: '1',
      zip_code: '666',
      city: 'Malibu Beach',
      country_code: 'US',
    },
  ],
  external_customer_id: '1A',
  phone_numbers: [
    {
      phone_number_type: 'PRIVATE',
      value: '+1 666 666 666',
    },
  ],
};

export const customerSearchCriteria: CustomerSearchRequest = {
  firstName: 'John',
  lastName: 'Doe',
  city: 'Musterst',
};
export const moreThan10customerSearchCriteria: CustomerSearchRequest = {
  firstName: 'J*',
  lastName: 'D*',
  city: 'M*',
};
export const notFoundCustomerSearchCriteria: CustomerSearchRequest = {
  firstName: 'Johnny',
  lastName: 'Roe',
  email: 'johnny@roe.example',
};
export const testCustomersResponse = {
  results: [testCustomerResponse],
};

export const offerMock: OfferResponse = {
  offer: {
    items: [],
    totalProductPrice: 0,
    shippingCost: 0,
    totalSavings: 0,
    totalPrice: 0,
    currency: Currency.euro,
  },
};

export const mockToken: AuthorizationCodeResponse = {
  idToken:
    'eyJraWQiOiJONWJwTmFVMExYZXMxamdUSldKN0xQcHZXcGg0Zm81aWNmU2NiWUtJZUhnPSIsImFsZyI6IlJTMjU2In0.eyJhdF9oYXNoIjoidDJWQ3YyN05YcnF1eFc1cnNPb3V3QSIsInN1YiI6IjE1OTdkMWRhLWEzYTAtNDhmMy1hNzdlLWJjZGFlZWIxZjdiNyIsImF1ZCI6IjF1Ymh1YjJ1YnZ0bWxicGRqam0wN2ZiNHMwIiwidG9rZW5fdXNlIjoiaWQiLCJhdXRoX3RpbWUiOjE1ODYzNzYzMzMsImlzcyI6Imh0dHBzOlwvXC9jb2duaXRvLWlkcC5ldS1jZW50cmFsLTEuYW1hem9uYXdzLmNvbVwvZXUtY2VudHJhbC0xX1dsU0lxM3puMSIsImNvZ25pdG86dXNlcm5hbWUiOiJhaHphYiIsImV4cCI6MTU4NjQ0NDk1MSwiZ2l2ZW5fbmFtZSI6ImFiZGVsIiwiaWF0IjoxNTg2NDQxMzUxLCJmYW1pbHlfbmFtZSI6ImFoemFiIiwiZW1haWwiOiJhLmFiZGVsaGFrQGhzZTI0LmRlIn0.FtS0Po6oh-09mPoMuYyw4gPsMgEtdRKlCx1jcDb5YvczxZiJx1Va_QIN3YMHjamOHVRghmX9zJ2p6bGBuBZarF27qOep-ZdohyzkWgtQOgiFcFwkWXcznHgn1Y6durfqFMquLMEhvHecoY1HKyZ0apqrGLEmDDdkCxHAPLyVEOlN975GeQE2RS63br2MjR5KS0GORdQEdQFhHI0uZ7XVcVN2tf6_TUr7PvIH3ESdPKLeQ0HS6VRv1AVssLNEYv9FHnck8KE9-BBIeTjwoQuUUFyhe-NEm9wuSPfTeR80iAuvLQivq8aZ_-b4mLXXO2_LD2XM-D-ZyD44FjUtmAY5HA',
  refreshToken:
    'eyJjdHkiOiJKV1QiLCJlbmMiOiJBMjU2R0NNIiwiYWxnIjoiUlNBLU9BRVAifQ.XhZP17mELxhsIMMsiojtcFBT2D-QBSyAowHMxTYNTEDpx0wsJQAJ2LxCo5tl1PvBQil94FfNC_7i51AOYXMlslTuV-loNSO7TJWUkMfaC0Yac4CPiXQV3oMLEEavlf62LPmpxr94kMSLo4ioZ3jHQMe9QTzBcL0hqPUxMdZ9XITD152jOrpkN6pnltTa2BOw0Thyg0Yk681JjMobV_m_mQRHCct57Dxa3VOBmZ0d8xKCGZu5t0M3dCONEYDBIbTvXTiTMK9co5G_FqwERQFelWKJXumY7SImq-6-GAsjxAH5WYeUCcBWhHO1QFq5W0t_DapKtS3kQDNxxxFvYWGFIA.EAG01WrvN4KQHcgC.OZEhw9ULC54AsVh97UoytEtyCPA9PIr0_oJEwEszR4UaqMkrfsgWAxJ3CedAYGc8oM5rWkPTdMZtdAGgPW-_byjpHmDEeMsJL2fFgkTHYyC9IlFBzUOXZ2VIl76BKxzMl2Ld39R9NeuSv75cC22JNdrdGx-5E7VEVkh4B-ZMqWKOxl0JXphskmstb0eu2ekOj5vvXtGzK76D92-xGSeFLiScCLkKDq4y_P-Pv_MK69W7ixZNLPC5l4JIwXPArP0EOL1ArBsDhrT1d4sQc-KnEeba8M0-PKBXvEt2BdTG-68RNN6YVb9YjH7d_zrNtrfkz8vhRvv7jUrJj-Vr9bytploDFQnm-bgIlmEJfJoARaEwoH_z6QSdgBObRXB-VL19AsOtkoZ4l7u6a-2Y3U268T9ET_TYoU6ppZAN_GSremA6it8i8IKscCnpmaxose1Mla_7F6IlsaZRESUZi1A1ZNh_DTpgB_1SsV1G3ujVxGIj-2CwY3XecZM1QUOv0a3gjnUYlCpunQwTo1sSKrN8nKZYBeF09HaedAZL0Rb_H9lUxukrrdfVMiek_X_kzVEQ0hTez2rQrDst_uL96ry8vg6Sse6xpL-TCvSc_IvxHIlrt3xsp7zsaSfNnMY1UsYtZvkO9suvCXjcDPwb1ua1xTuppQQRxlyebHYKzg528cPKanMvQi71SzMaFvDVRaz3qREIF1Z6x9p5FlzdVJLbEoTGFz4O1n3RgcKJZcYZynFOAuGID0WbAS8KoMzQQqJr5rN8d56Z7w7uQiai_3C9d4-r9k_tKOLSxQTwpTTCMssGZGuPzrpAc-n5402siq8Hd3O8yfh-icqaHyCaFF8HIWb5NhfNgSkzYLAFCppFgXDI_Gh09e_26v2pggF17MXegUHppKmWGouCsU3R7gLr5Ac6boqWA5iDOQvZ-K8I6c9gO9WokjqtwANNhbEAoXA48EpuQ0WholNSByS8ktkn6rQCcqVIomY7bAwxyWQBip-AmUr65Re3qdMk_MWL3zPAGe1GpvyiGI3ehm2TpYARctTLDols9FGg1-a-XUwaq4EunRIzcXU894j8LIhPBHax4a2VfTHbmg4m4n2F2H5vaYF9SMKk5eEQg9kJenhNM4KKEjN69VvhDMLxkmBXy1bPSYRr-Vlm23Jvqk4KDPuiNMqpMhRxCDx8EDF-Ls4.2q_wW7oPYLHwmGX3ksmgFg',
  accessToken:
    'eyJjdHkiOiJKV1QiLCJlbmMiOiJBMjU2R0NNIiwiYWxnIjoiUlNBLU9BRVAifQ.XhZP17mELxhsIMMsiojtcFBT2D-QBSyAowHMxTYNTEDpx0wsJQAJ2LxCo5tl1PvBQil94FfNC_7i51AOYXMlslTuV-loNSO7TJWUkMfaC0Yac4CPiXQV3oMLEEavlf62LPmpxr94kMSLo4ioZ3jHQMe9QTzBcL0hqPUxMdZ9XITD152jOrpkN6pnltTa2BOw0Thyg0Yk681JjMobV_m_mQRHCct57Dxa3VOBmZ0d8xKCGZu5t0M3dCONEYDBIbTvXTiTMK9co5G_FqwERQFelWKJXumY7SImq-6-GAsjxAH5WYeUCcBWhHO1QFq5W0t_DapKtS3kQDNxxxFvYWGFIA.EAG01WrvN4KQHcgC.OZEhw9ULC54AsVh97UoytEtyCPA9PIr0_oJEwEszR4UaqMkrfsgWAxJ3CedAYGc8oM5rWkPTdMZtdAGgPW-_byjpHmDEeMsJL2fFgkTHYyC9IlFBzUOXZ2VIl76BKxzMl2Ld39R9NeuSv75cC22JNdrdGx-5E7VEVkh4B-ZMqWKOxl0JXphskmstb0eu2ekOj5vvXtGzK76D92-xGSeFLiScCLkKDq4y_P-Pv_MK69W7ixZNLPC5l4JIwXPArP0EOL1ArBsDhrT1d4sQc-KnEeba8M0-PKBXvEt2BdTG-68RNN6YVb9YjH7d_zrNtrfkz8vhRvv7jUrJj-Vr9bytploDFQnm-bgIlmEJfJoARaEwoH_z6QSdgBObRXB-VL19AsOtkoZ4l7u6a-2Y3U268T9ET_TYoU6ppZAN_GSremA6it8i8IKscCnpmaxose1Mla_7F6IlsaZRESUZi1A1ZNh_DTpgB_1SsV1G3ujVxGIj-2CwY3XecZM1QUOv0a3gjnUYlCpunQwTo1sSKrN8nKZYBeF09HaedAZL0Rb_H9lUxukrrdfVMiek_X_kzVEQ0hTez2rQrDst_uL96ry8vg6Sse6xpL-TCvSc_IvxHIlrt3xsp7zsaSfNnMY1UsYtZvkO9suvCXjcDPwb1ua1xTuppQQRxlyebHYKzg528cPKanMvQi71SzMaFvDVRaz3qREIF1Z6x9p5FlzdVJLbEoTGFz4O1n3RgcKJZcYZynFOAuGID0WbAS8KoMzQQqJr5rN8d56Z7w7uQiai_3C9d4-r9k_tKOLSxQTwpTTCMssGZGuPzrpAc-n5402siq8Hd3O8yfh-icqaHyCaFF8HIWb5NhfNgSkzYLAFCppFgXDI_Gh09e_26v2pggF17MXegUHppKmWGouCsU3R7gLr5Ac6boqWA5iDOQvZ-K8I6c9gO9WokjqtwANNhbEAoXA48EpuQ0WholNSByS8ktkn6rQCcqVIomY7bAwxyWQBip-AmUr65Re3qdMk_MWL3zPAGe1GpvyiGI3ehm2TpYARctTLDols9FGg1-a-XUwaq4EunRIzcXU894j8LIhPBHax4a2VfTHbmg4m4n2F2H5vaYF9SMKk5eEQg9kJenhNM4KKEjN69VvhDMLxkmBXy1bPSYRr-Vlm23Jvqk4KDPuiNMqpMhRxCDx8EDF-Ls4.2q_wW7oPYLHwmGX3ksmgFg',
  expiresIn: 86400,
  tokenType: 'Bearer',
};

export const mockState: RootStateType = {
  app: {
    language: LanguageOptions.de,
    country: CountryCode.de,
    loading: false,
    authError: false,
    notifications: [],
    snackNotifications: [],
    idToken:
      'eyJraWQiOiJONWJwTmFVMExYZXMxamdUSldKN0xQcHZXcGg0Zm81aWNmU2NiWUtJZUhnPSIsImFsZyI6IlJTMjU2In0.eyJhdF9oYXNoIjoidDJWQ3YyN05YcnF1eFc1cnNPb3V3QSIsInN1YiI6IjE1OTdkMWRhLWEzYTAtNDhmMy1hNzdlLWJjZGFlZWIxZjdiNyIsImF1ZCI6IjF1Ymh1YjJ1YnZ0bWxicGRqam0wN2ZiNHMwIiwidG9rZW5fdXNlIjoiaWQiLCJhdXRoX3RpbWUiOjE1ODYzNzYzMzMsImlzcyI6Imh0dHBzOlwvXC9jb2duaXRvLWlkcC5ldS1jZW50cmFsLTEuYW1hem9uYXdzLmNvbVwvZXUtY2VudHJhbC0xX1dsU0lxM3puMSIsImNvZ25pdG86dXNlcm5hbWUiOiJhaHphYiIsImV4cCI6MTU4NjQ0NDk1MSwiZ2l2ZW5fbmFtZSI6ImFiZGVsIiwiaWF0IjoxNTg2NDQxMzUxLCJmYW1pbHlfbmFtZSI6ImFoemFiIiwiZW1haWwiOiJhLmFiZGVsaGFrQGhzZTI0LmRlIn0.FtS0Po6oh-09mPoMuYyw4gPsMgEtdRKlCx1jcDb5YvczxZiJx1Va_QIN3YMHjamOHVRghmX9zJ2p6bGBuBZarF27qOep-ZdohyzkWgtQOgiFcFwkWXcznHgn1Y6durfqFMquLMEhvHecoY1HKyZ0apqrGLEmDDdkCxHAPLyVEOlN975GeQE2RS63br2MjR5KS0GORdQEdQFhHI0uZ7XVcVN2tf6_TUr7PvIH3ESdPKLeQ0HS6VRv1AVssLNEYv9FHnck8KE9-BBIeTjwoQuUUFyhe-NEm9wuSPfTeR80iAuvLQivq8aZ_-b4mLXXO2_LD2XM-D-ZyD44FjUtmAY5HA',
    refreshToken:
      'eyJjdHkiOiJKV1QiLCJlbmMiOiJBMjU2R0NNIiwiYWxnIjoiUlNBLU9BRVAifQ.XhZP17mELxhsIMMsiojtcFBT2D-QBSyAowHMxTYNTEDpx0wsJQAJ2LxCo5tl1PvBQil94FfNC_7i51AOYXMlslTuV-loNSO7TJWUkMfaC0Yac4CPiXQV3oMLEEavlf62LPmpxr94kMSLo4ioZ3jHQMe9QTzBcL0hqPUxMdZ9XITD152jOrpkN6pnltTa2BOw0Thyg0Yk681JjMobV_m_mQRHCct57Dxa3VOBmZ0d8xKCGZu5t0M3dCONEYDBIbTvXTiTMK9co5G_FqwERQFelWKJXumY7SImq-6-GAsjxAH5WYeUCcBWhHO1QFq5W0t_DapKtS3kQDNxxxFvYWGFIA.EAG01WrvN4KQHcgC.OZEhw9ULC54AsVh97UoytEtyCPA9PIr0_oJEwEszR4UaqMkrfsgWAxJ3CedAYGc8oM5rWkPTdMZtdAGgPW-_byjpHmDEeMsJL2fFgkTHYyC9IlFBzUOXZ2VIl76BKxzMl2Ld39R9NeuSv75cC22JNdrdGx-5E7VEVkh4B-ZMqWKOxl0JXphskmstb0eu2ekOj5vvXtGzK76D92-xGSeFLiScCLkKDq4y_P-Pv_MK69W7ixZNLPC5l4JIwXPArP0EOL1ArBsDhrT1d4sQc-KnEeba8M0-PKBXvEt2BdTG-68RNN6YVb9YjH7d_zrNtrfkz8vhRvv7jUrJj-Vr9bytploDFQnm-bgIlmEJfJoARaEwoH_z6QSdgBObRXB-VL19AsOtkoZ4l7u6a-2Y3U268T9ET_TYoU6ppZAN_GSremA6it8i8IKscCnpmaxose1Mla_7F6IlsaZRESUZi1A1ZNh_DTpgB_1SsV1G3ujVxGIj-2CwY3XecZM1QUOv0a3gjnUYlCpunQwTo1sSKrN8nKZYBeF09HaedAZL0Rb_H9lUxukrrdfVMiek_X_kzVEQ0hTez2rQrDst_uL96ry8vg6Sse6xpL-TCvSc_IvxHIlrt3xsp7zsaSfNnMY1UsYtZvkO9suvCXjcDPwb1ua1xTuppQQRxlyebHYKzg528cPKanMvQi71SzMaFvDVRaz3qREIF1Z6x9p5FlzdVJLbEoTGFz4O1n3RgcKJZcYZynFOAuGID0WbAS8KoMzQQqJr5rN8d56Z7w7uQiai_3C9d4-r9k_tKOLSxQTwpTTCMssGZGuPzrpAc-n5402siq8Hd3O8yfh-icqaHyCaFF8HIWb5NhfNgSkzYLAFCppFgXDI_Gh09e_26v2pggF17MXegUHppKmWGouCsU3R7gLr5Ac6boqWA5iDOQvZ-K8I6c9gO9WokjqtwANNhbEAoXA48EpuQ0WholNSByS8ktkn6rQCcqVIomY7bAwxyWQBip-AmUr65Re3qdMk_MWL3zPAGe1GpvyiGI3ehm2TpYARctTLDols9FGg1-a-XUwaq4EunRIzcXU894j8LIhPBHax4a2VfTHbmg4m4n2F2H5vaYF9SMKk5eEQg9kJenhNM4KKEjN69VvhDMLxkmBXy1bPSYRr-Vlm23Jvqk4KDPuiNMqpMhRxCDx8EDF-Ls4.2q_wW7oPYLHwmGX3ksmgFg',
    modal: {
      opened: [],
    },
    session: {
      loading: false,
    },
  },
  order: {
    orderDisplay: {
      loading: false,
    },
    newOrder: {
      loading: true,
      orderDeliveryTimes: [],
    },
    orderEntry: {
      searchTerm: '',
      subscribableOrderLineItemsDetails : {},
      orderLineItems: [
        {
          id: 'd4052382-6df3-4fd6-a411-a11e8f839c65',
          basketId: '8edbed41-5cea-4cd8-9285-d6a8dd4b33ad',
          baseProductNo: '406123',
          quantity: 1,
          name: {
            long: 'Sonnenbrille',
          },
          availableStockAmount: 6,
          brand: {
            brandName: 'Judith Williams',
          },
          mediaUris: [
            {
              uri: '/406123002/406123002_3504390c-9b5c-4998-953b-f66ac38c3428',
              mediaType: 'image',
            },
            {
              uri: '/406123002/406123002_d8c2f70a-49c0-4caa-b394-71a07dba6252',
              mediaType: 'image',
            },
          ],
          stockLevel: StockLevel.available,
          status: {
            type: 'SELLABLE',
          },
          variant: {
            sku: '406123002',
            price: {
              currencyCode: Currency.euro,
              value: 49.99,
            },
            dimensions: [
              {
                type: DimensionKind.color,
                value: 'hellblau',
              },
            ],
            images: [
              {
                uri: '/406123002/406123002_3504390c-9b5c-4998-953b-f66ac38c3428',
                mediaType: 'image',
              },
              {
                uri: '/406123002/406123002_d8c2f70a-49c0-4caa-b394-71a07dba6252',
                mediaType: 'image',
              },
            ],
          },
        },
      ],
      searchLoading: false,
      getBasketLoading: false,
      basketItemsStatus: {},
      basketId: '8edbed41-5cea-4cd8-9285-d6a8dd4b33ad',
      quantityFields: {},
      orderLineItemsErrors: {},
      offer: offerMock.offer,
      isRekla: false
    },
    voucher: {
      code: 'ADGFRR123547',
      loading: false,
      pendingRedeemVouchers: [],
      redeemableVouchers: [],
      nonRedeemableVouchers: [],
      restrictions: [],
      showDeletedVoucherHint: false,
    },
    salesChannel: {
      value: DEFAULT_SALES_CHANNEL,
      loading: false,
    },
    salesOffice: DEFAULT_SALES_OFFICE,
    sourceChannel: SourceChannels.TELE,
  },
  product: {
    search: {
      loading: false,
      bnr: '',
      longTextSearch: '',
      searchResponse: {
        products: [],
        totalProducts: 0,
      },
    },
    upsells: {
      upsellsLocation: 'ordertable',
      upsells: [],
    },
    suggestions: {
      loading: false,
      data: [
        {
          name: 'Chung Shi Vitalschuhe',
          type: 'brand',
          image: '/370246/370246_d8f68c76-5816-4657-a490-16515dc7cb5f',
          searchParams: {
            query: '*',
            filters: [
              {
                name: 'Brand',
                values: [
                  {
                    value: 'Chung Shi Vitalschuhe',
                  },
                ],
                substring: false,
              },
            ],
            channel: 'dede',
          },
          attributes: {
            masterId: '370246',
          },
          hitCount: 1,
        },
      ],
    },
    stocks: {
      loading: false,
      data: [
        { sku: '403060', amount: 149 },
        { sku: '403061', amount: 40 },
      ],
      waitlists: [],
    },
    details: {
      loading: true,
    },
    stockReminder: {
      loading: false,
    },
    createWaitlistItem: {
      selectedPaymentMethod: PaymentMethod.invoice,
    },
    createSubscription: {
      selectedPaymentMethod: PaymentMethod.invoice,
      nextOrderDate: '2023-01-01',
      sourceChannel: SourceChannels.TELE,
      salesOffice: DEFAULT_SALES_OFFICE,
      salesChannel: DEFAULT_SALES_CHANNEL,
    },
  },
  customer: {
    info: {
      loading: false,
      customerNumberField: '',
      billingAsDeliveryAddress: false,
      customerResponse: {
        id: '12345678',
        salutation: Salutation.mister,
        firstName: 'test',
        lastName: 'test',
        email: 'test@gmail.com',
        dateOfBirth: '2002-04-02',
        phoneNumbers: [
          {
            phoneNumberType: 'PRIVATE',
            value: '0651123999',
          },
        ],
        phoneNumber: '0651123999',
        billingAddress: {
          type: AddressType.Billing,
          street: 'Test test',
          streetNumber: '12',
          zipCode: '44000',
          city: 'Munich',
          countryCode: CountryCode.de,
        },
        origin: '',
        externalCustomerId: '12345678',
        deliveryAddresses: [
          {
            id: '1',
            type: DeliveryAddressType.Postal,
            salutation: Salutation.miss,
            firstName: 'Sue',
            lastName: 'Müller',
            street: 'Schleißheimerstr.',
            streetNumber: '1',
            zipCode: '56789',
            city: 'Schleißheimerstadt',
            countryCode: CountryCode.de,
          },
        ],
        isCreateDeliveryAddressAllowed: true,
      },
      selectedDeliveryAddress: {
        id: '1',
        type: DeliveryAddressType.Postal,
        salutation: Salutation.miss,
        firstName: 'Sue',
        lastName: 'Müller',
        street: 'Schleißheimerstr.',
        streetNumber: '1',
        zipCode: '56789',
        city: 'Schleißheimerstadt',
        countryCode: CountryCode.de,
      },
      customerOrderRestriction: OrderRestriction.duplicate,
      isCustomerDataChanged: false,
      blockedDeliveryAddresses: [],
      infoPost: {
        ringsTemplate: { loading: false, letterSent: false },
      },
    },
    search: {
      loading: false,
      customers: [],
    },
    orderHistory: {
      externalCustomerId: '123',
      loading: false,
      isLoadingMore: false,
      cancelOrderInProgress: false,
      data: [],
      offset: 1,
      totalOrders: 0,
      totalFilteredOrders: 0,
      filters: {},
    },
    waitlistHistory: waitlistHistoryInitialState,
    subscriptionHistory: {
      externalCustomerId: '',
      loading: false,
      searchCriteria: {
        status: SubscriptionStatusRequest.ALL,
      },
    },
    editSubscription: {
      loading: false,
    },
    addressSuggestions: {
      loading: false,
      loadingSuggestedcities: false,
      addresses: {
        completeAddresses: [{
          city: 'Gotham',
          zipCode: '12346',
          street: 'Street',
          streetNumber: '1',
        }],
        partialAddresses: [],
      },
      city: 'Gotham',
      zipCode: '12346',
      street: 'Street',
      streetNumber: '1',
      countryCode: CountryCode.de,
    },
    suggestions: {
      loading: false,
    },
    pinVerification: {
      ...customerPinInitialState,
    },
  },
  payment: {
    error: null,
    loading: false,
    availablePaymentMethods: [
      { enabled: true, method: PaymentMethod.invoice },
      { enabled: true, method: PaymentMethod.directDebit },
      { enabled: true, method: PaymentMethod.cashOnDelivery },
      { enabled: true, method: PaymentMethod.installments },
    ],
    bankingInfo: {
      iban: '',
      accountHolder: '',
      bankName: '',
      ibanValid: false,
      firstTime: true,
      usesPrefilledIban: BankingDetailsState.initial,
    },
    loadingPrefilledIban: false,
    selectedPaymentMethod: PaymentMethod.invoice,
    installments: {
      dueDay: 1,
      modalStep: InstallmentModalSteps.CHOOSE_PLAN,
      //TODO this will be changed on the next task https://edutainment.atlassian.net/browse/RKS-120
      currentInstallmentPlan: {
        installmentCount: 3,
        installment: 15.63,
        finalInstallment: 15.63,
        interestRate: 13.12,
        totalInterest: 0.96,
        total: 46.89,
      },
    },
    installmentsFactoring: {
      modalStep: InstallmentFactoringModalSteps.CHOOSE_PLAN,
      installmentFactoringModalMode: InstallmentFactoringModalMode.NORMAL_MODE,
    },
    warnings: {},
  },
  serviceVoucher: {
    code: ServiceVoucher.systemFehler.valueOf(),
  },

  router: {
    location: {
      pathname: '/orderapp',
      search: '',
      hash: '',
      state: '',
    },
    action: 'POP',
  },
  offers: {
    loading: false,
    topDeals: {
      daily: [],
      weekly: [],
      monthly: [],
    },
    tvOffers: {
      hse24: [],
      hse24_extra: [],
      hse24_trend: [],
    },
    showOffers: false,
  },
  notifications: notificationsTickerInitialState,
  editOrder: editOrderInitialState,
  inflightOrder: inflightOrderInitialState,
  editedOrder: editOrderInitialState,
  recreateOrder: recreateOrderInitialState,
  subscriptionManagement: subscriptionManagementInitialState,
  subscriptionReport: subscriptionReportInitialState,
  previewLetter: previewLetterStateInitialState,
  orderPreCheck: orderPreCheckInitialState,
  chOrderEntry: initialCHOrderEntryState,
  bot: {
    botRequest: botRequestInitialState
  }
};

export const botRequestInfoMock: BotRequestInfo = {
  code: 1,
  comment: 'comment',
  externalCustomerId: '1',
  reason: BotRequestReason.ASK_FOR_AGENT,
  isAuthenticated: true
};

export const customerMock: Customer = {
  id: '12345678',
  salutation: Salutation.mister,
  firstName: 'test',
  lastName: 'test',
  email: 'test@gmail.com',
  dateOfBirth: '02.04.2002',
  phoneNumbers: [
    {
      phoneNumberType: 'PRIVATE',
      value: '0651123999',
    },
  ],
  phoneNumber: '0651123999',
  originalPhoneNumber: '0651123999',
  billingAddress: {
    city: 'Gotham',
    zipCode: '12346',
    street: 'Street',
    streetNumber: '1',
    countryCode: CountryCode.de,
  },
  origin: '',
  externalCustomerId: '123456',
  deliveryAddresses: [
    {
      id: '1',
      salutation: Salutation.miss,
      firstName: 'Sue',
      lastName: 'Müller',
      street: 'Schleißheimerstr.',
      streetNumber: '1',
      zipCode: '56789',
      city: 'Schleißheimerstadt',
      countryCode: CountryCode.de,
    },
  ],
};

export const customerRequestMock = {
  salutation: customerMock.salutation!,
  firstName: customerMock.firstName,
  lastName: customerMock.lastName,
  email: customerMock.email,
  phone: customerMock.phoneNumber,
  dateOfBirth: customerMock.dateOfBirth,
  street: customerMock.billingAddress!.street,
  streetNumber: customerMock.billingAddress!.streetNumber,
  zipCode: customerMock.billingAddress!.zipCode,
  city: customerMock.billingAddress!.city,
  countryCode: customerMock.billingAddress!.countryCode,
  addressAddition: customerMock.billingAddress!.addressAddition,
  skipDoubleCheck: customerMock.skipDoubleCheck,
  externalCustomerId: customerMock.externalCustomerId,
  dplCustomerId: customerMock.id,
  crmPartnerId: customerMock.externalCustomerId,
};

export const customerConsentBooleanFlagsMock: CustomerConsentBooleanFlags = {
  emailNewsletter: true,
  exportDhlEmail: true,
  phoneMarketResearch: true,
  printedAdvertising: true,
};
export const customerPrivacyBooleanFlagsMock: DataProtectionBooleanFlags = {
  isRequested: true,
  infoRead: true,
  infoSend: false,
};

export const mockAddress: PostalDeliveryAddress = {
  salutation: Salutation.mister,
  firstName: '',
  lastName: '',
  city: 'Gotham',
  zipCode: '12346',
  street: 'Street',
  streetNumber: '1',
  countryCode: CountryCode.de,
  type: DeliveryAddressType.Postal,
};

export const orderMock: Order = {
  id: '12345',
  customer: {
    salutation: Salutation.mister,
    firstName: 'test',
    lastName: 'test',
    email: 'test@gmail.com',
    dateOfBirth: '2002-04-02',
    phone: '+495885744',
    city: 'Gotham',
    zipCode: '12346',
    street: 'Street',
    streetNumber: '1',
    countryCode: CountryCode.de,
    crmPartnerId: '1332345',
  },
  items: [
    {
      id: '1234',
      name: { long: 'gsgsd' },
      variant: {
        price: {
          value: 12,
        },
        sku: '1234343',
      },
      quantity: 1,
      createdAt: '2022-12-27T10:15:37Z',
    },
  ],
  currency: Currency.euro,
  totalPrice: 10,
  payment: {
    method: PaymentMethod.invoice,
  },
  salesOffice: '4001',
  delivery: {
    method: DeliveryMethod.dhl,
    price: 1.2,
  },
  deliveryAddress: {
    type: DeliveryAddressType.Postal,
    salutation: Salutation.miss,
    firstName: 'Sue',
    lastName: 'Müller',
    street: 'Schleißheimerstr.',
    streetNumber: '1',
    zipCode: '56789',
    city: 'Schleißheimerstadt',
    countryCode: CountryCode.de,
    addressAddition: 'Schleißheimerstr',
  },
  createdAt: '10-05-2020',
  statusHistory: [],
  processingSystem: ProcessingSystem.CRM,
  sourceChannel: SourceChannels.ECOM,
  voucher: {
    code: 'voucher',
    value: 19,
    type: 'value',
  },
  orderType: OrderType.EXISTING_CUSTOMER_ORDER,
  source: OrderSource.SHOP,
};

export const createOrderResultExample: CreateOrderResultResponse = {
  id: '12345',
  customer: {
    salutation: Salutation.mister,
    firstName: 'test',
    lastName: 'test',
    email: 'test@gmail.com',
    dateOfBirth: '2002-04-02',
    phone: '+495885744',
    city: 'Gotham',
    zipCode: '12346',
    street: 'Street',
    streetNumber: '1',
    countryCode: CountryCode.de,
  },
  items: [
    {
      id: '1234',
      name: { long: 'gsgsd' },
      variant: {
        price: {
          value: 12,
        },
        sku: '1234343',
      },
      quantity: 1,
      createdAt: '2022-12-27T10:15:37Z',
      stockLevel: StockLevel.available,
      mediaUris: [],
      baseProductNo: '1234343',
    },
  ],
  currency: Currency.euro,
  totalPrice: 10,
  payment: {
    method: PaymentMethod.invoice,
  },
  delivery: {
    method: DeliveryMethod.dhl,
    price: 1.2,
  },
  deliveryAddress: {
    type: DeliveryAddressType.Postal,
    salutation: Salutation.miss,
    firstName: 'Sue',
    lastName: 'Müller',
    street: 'Schleißheimerstr.',
    streetNumber: '1',
    zipCode: '56789',
    city: 'Schleißheimerstadt',
    countryCode: CountryCode.de,
    addressAddition: 'Schleißheimerstr',
  },
  voucher: {
    code: 'voucher',
    value: 19,
    type: 'value',
  },
  totalProductPrice: 10.5,
  totalSavings: 0,
};

export const existingCustomerOrderRequest: GuestOrderRequest = {
  salesOffice: DEFAULT_SALES_OFFICE,
  salesChannel: DEFAULT_SALES_CHANNEL,
  voucher: {
    code: 'ADGFRR123547',
  },
  serviceVoucher: ServiceVoucher.systemFehler,
  customer: {
    dateOfBirth: '2002-04-02',
    salutation: Salutation.mister,
    firstName: 'test',
    lastName: 'test',
    email: 'test@gmail.com',
    street: 'Test test',
    phone: '0651123999',
    streetNumber: '12',
    zipCode: '44000',
    city: 'Munich',
    countryCode: CountryCode.de,
    addressAddition: undefined,
    createAccount: false,
    externalCustomerId: '123456'
  },
  deliveryAddress: {
    salutation: Salutation.miss,
    firstName: 'Sue',
    lastName: 'Müller',
    street: 'Schleißheimerstr.',
    streetNumber: '1',
    zipCode: '56789',
    city: 'Schleißheimerstadt',
    addressAddition: undefined,
    countryCode: CountryCode.de,
  },
  payment: { method: PaymentMethod.invoice },
  isShippingOverwritten: true,
  sourceChannel: SourceChannels.TELE,
};

export const basketMock: BasketResponse = {
  id: '123456',
  identityId: '1234578',
  items: [],
  subtotal: 0,
  shippingCost: 0,
  totalSaving: 0,
  total: 0,
};

export const emptyProductMock: ProductDetails = {
  baseProductNo: '',
  name: {
    short: '',
    long: '',
  },
  status: ProductStatus.Archived,
  defaultVariantSku: '',
  variants: [],
};

export const productMock: ProductDetails = {
  baseProductNo: '428474',
  country: CountryCode.de,
  defaultVariantSku: '428474001',
  name: {
    short: 'Shirt mit Tuch',
    long: 'Shirt mit Tuch',
  },
  description: 'Von Alfredo Pauly ',
  additionalInformation: 'this is additional Information',
  headline: 'Zusammen oder getrennt getragen ein Hingucker',
  status: ProductStatus.Sellable,
  media: [
    {
      uri: '/428474/428474_02b7d71e-54b7-41f0-bb44-cea237df93bf',
      mediaType: 'image',
    },
  ],
  usps: [
    'stilvolles Ensemble',
    'Rundhalsausschnitt & 3/4-Ärmel',
    'Schal mit Blumenprint und Ausbrenner',
  ],
  energyEfficiency: {},
  dimensions: [
    {
      type: DimensionType.SIZE,
      values: [
        {
          value: '36',
        },
      ],
    },
    {
      type: DimensionType.COLOR,
      values: [
        {
          value: 'marine',
          codeHex: '251F4B',
        },
      ],
    },
  ],
  brand: {
    brandName: 'Alfredo Pauly',
    productLineName: 'Alfredo Pauly Mode',
    brandIcon: {
      uri: '/de/brands/18248666/18248666_01_brand-logos.png',
      mediaType: 'image',
    },
  },
  variants: [
    {
      sku: '428474001',
      status: ProductStatus.Sellable,
      price: {
        countryCode: 'DE',
        currencyCode: Currency.euro,
        label: 'Shopping-Preis',
        value: 59.99,
        validFrom: '2020-03-07T00:00:00+01:00',
        validTo: '2020-05-17T23:59:59+02:00',
        reference: {
          label: 'HSE24-Preis',
          saving: 9.99,
          savingPercent: 14,
          value: 69.98,
          type: 'T',
        },
      },
      dimensions: [
        {
          type: DimensionType.COLOR,
          value: 'marine',
        },
        {
          type: DimensionType.SIZE,
          value: '36',
        },
      ],
      images: [
        {
          uri: '/428474001/428474001_cdfdcf1e-8245-4097-9732-141c08c79746',
          mediaType: 'image',
        },
        {
          uri: '/428474001/428474001_162dfa8e-794b-42b4-9319-5e71ca5d8aa1',
          mediaType: 'image',
        },
        {
          uri: '/428474001/428474001_10c8514e-45b5-4666-b8b5-3397d0f14913',
          mediaType: 'image',
        },
      ],
      isSellable: true,
    },
    {
      sku: '428474002',
      status: ProductStatus.Sellable,
      price: {
        countryCode: 'DE',
        currencyCode: Currency.euro,
        label: 'Shopping-Preis',
        value: 59.99,
        validFrom: '2020-03-07T00:00:00+01:00',
        validTo: '2020-05-17T23:59:59+02:00',
        reference: {
          label: 'HSE24-Preis',
          saving: 9.99,
          savingPercent: 14,
          value: 69.98,
          type: 'T',
        },
      },
      dimensions: [
        {
          type: DimensionType.COLOR,
          value: 'marine',
        },
        {
          type: DimensionType.SIZE,
          value: '38',
        },
      ],
      images: [
        {
          uri: '/428474001/428474001_cdfdcf1e-8245-4097-9732-141c08c79746',
          mediaType: 'image',
        },
        {
          uri: '/428474001/428474001_162dfa8e-794b-42b4-9319-5e71ca5d8aa1',
          mediaType: 'image',
        },
        {
          uri: '/428474001/428474001_10c8514e-45b5-4666-b8b5-3397d0f14913',
          mediaType: 'image',
        },
      ],
      isSellable: true,
    },
  ],
  seo: {
    description:
      'Alfredo Pauly Set: Shirt mit Blumendruck-Tuch und weitere Mode und Accessoires jetzt online bei HSE24.de bestellen!',
    title: 'Alfredo Pauly Set Shirt mit Tuch hier online',
  },
  categoryPath: 'Mode/Shirts & Tops/3-4 Arm',
  created: '2019-08-22T02:12:21+02:00',
  newInShop: '2020-03-09T00:00:00+01:00',
  tvOrTopDealInfo: 'HSE TREND',
  itemSource: ItemSource.REGULAR,
  countryVisibilities: [CountryCode.de, CountryCode.at],
};

export const productWithSubscriptionSellableStatusMock: ProductDetails = {
  baseProductNo: '428474',
  country: CountryCode.de,
  defaultVariantSku: '428474001',
  name: {
    short: 'Shirt mit Tuch',
    long: 'Shirt mit Tuch',
  },
  description: 'Von Alfredo Pauly ',
  additionalInformation: 'this is additional Information',
  headline: 'Zusammen oder getrennt getragen ein Hingucker',
  status: ProductStatus.SubscriptionSellable,
  media: [
    {
      uri: '/428474/428474_02b7d71e-54b7-41f0-bb44-cea237df93bf',
      mediaType: 'image',
    },
  ],
  usps: [
    'stilvolles Ensemble',
    'Rundhalsausschnitt & 3/4-Ärmel',
    'Schal mit Blumenprint und Ausbrenner',
  ],
  energyEfficiency: {},
  dimensions: [
    {
      type: DimensionType.SIZE,
      values: [
        {
          value: '36',
        },
      ],
    },
    {
      type: DimensionType.COLOR,
      values: [
        {
          value: 'marine',
          codeHex: '251F4B',
        },
      ],
    },
  ],
  brand: {
    brandName: 'Alfredo Pauly',
    productLineName: 'Alfredo Pauly Mode',
    brandIcon: {
      uri: '/de/brands/18248666/18248666_01_brand-logos.png',
      mediaType: 'image',
    },
  },
  variants: [
    {
      sku: '428474001',
      status: ProductStatus.SubscriptionSellable,
      price: {
        countryCode: 'DE',
        currencyCode: Currency.euro,
        label: 'Shopping-Preis',
        value: 59.99,
        validFrom: '2020-03-07T00:00:00+01:00',
        validTo: '2020-05-17T23:59:59+02:00',
        reference: {
          label: 'HSE24-Preis',
          saving: 9.99,
          savingPercent: 14,
          value: 69.98,
          type: 'T',
        },
      },
      dimensions: [
        {
          type: DimensionType.COLOR,
          value: 'marine',
        },
        {
          type: DimensionType.SIZE,
          value: '36',
        },
      ],
      images: [
        {
          uri: '/428474001/428474001_cdfdcf1e-8245-4097-9732-141c08c79746',
          mediaType: 'image',
        },
        {
          uri: '/428474001/428474001_162dfa8e-794b-42b4-9319-5e71ca5d8aa1',
          mediaType: 'image',
        },
        {
          uri: '/428474001/428474001_10c8514e-45b5-4666-b8b5-3397d0f14913',
          mediaType: 'image',
        },
      ],
    },
    {
      sku: '428474002',
      status: ProductStatus.SubscriptionSellable,
      price: {
        countryCode: 'DE',
        currencyCode: Currency.euro,
        label: 'Shopping-Preis',
        value: 59.99,
        validFrom: '2020-03-07T00:00:00+01:00',
        validTo: '2020-05-17T23:59:59+02:00',
        reference: {
          label: 'HSE24-Preis',
          saving: 9.99,
          savingPercent: 14,
          value: 69.98,
          type: 'T',
        },
      },
      dimensions: [
        {
          type: DimensionType.COLOR,
          value: 'marine',
        },
        {
          type: DimensionType.SIZE,
          value: '38',
        },
      ],
      images: [
        {
          uri: '/428474001/428474001_cdfdcf1e-8245-4097-9732-141c08c79746',
          mediaType: 'image',
        },
        {
          uri: '/428474001/428474001_162dfa8e-794b-42b4-9319-5e71ca5d8aa1',
          mediaType: 'image',
        },
        {
          uri: '/428474001/428474001_10c8514e-45b5-4666-b8b5-3397d0f14913',
          mediaType: 'image',
        },
      ],
    },
  ],
  seo: {
    description:
      'Alfredo Pauly Set: Shirt mit Blumendruck-Tuch und weitere Mode und Accessoires jetzt online bei HSE24.de bestellen!',
    title: 'Alfredo Pauly Set Shirt mit Tuch hier online',
  },
  categoryPath: 'Mode/Shirts & Tops/3-4 Arm',
  created: '2019-08-22T02:12:21+02:00',
  newInShop: '2020-03-09T00:00:00+01:00',
  tvOrTopDealInfo: 'HSE TREND',
  itemSource: ItemSource.REGULAR,
  countryVisibilities: [CountryCode.de, CountryCode.at],
};

export const productWithOneVariant: ProductDetails = {
  baseProductNo: '428474',
  country: CountryCode.de,
  defaultVariantSku: '428474001',
  name: {
    short: 'Shirt mit Tuch',
    long: 'Shirt mit Tuch',
  },
  description: 'Von Alfredo Pauly ',
  additionalInformation: 'this is additional Information',
  headline: 'Zusammen oder getrennt getragen ein Hingucker',
  status: ProductStatus.Sellable,
  media: [
    {
      uri: '/428474/428474_02b7d71e-54b7-41f0-bb44-cea237df93bf',
      mediaType: 'image',
    },
  ],
  usps: [
    'stilvolles Ensemble',
    'Rundhalsausschnitt & 3/4-Ärmel',
    'Schal mit Blumenprint und Ausbrenner',
  ],
  energyEfficiency: {},
  dimensions: [
    {
      type: DimensionType.SIZE,
      values: [
        {
          value: '36',
        },
      ],
    },
    {
      type: DimensionType.COLOR,
      values: [
        {
          value: 'marine',
          codeHex: '251F4B',
        },
      ],
    },
  ],
  brand: {
    brandName: 'Alfredo Pauly',
    productLineName: 'Alfredo Pauly Mode',
    brandIcon: {
      uri: '/de/brands/18248666/18248666_01_brand-logos.png',
      mediaType: 'image',
    },
  },
  variants: [
    {
      sku: '428474',
      status: ProductStatus.Sellable,
      price: {
        countryCode: 'DE',
        currencyCode: Currency.euro,
        label: 'Shopping-Preis',
        value: 59.99,
        validFrom: '2020-03-07T00:00:00+01:00',
        validTo: '2020-05-17T23:59:59+02:00',
        reference: {
          label: 'HSE24-Preis',
          saving: 9.99,
          savingPercent: 14,
          value: 69.98,
          type: 'T',
        },
      },
      dimensions: [
        {
          type: DimensionType.COLOR,
          value: 'marine',
        },
        {
          type: DimensionType.SIZE,
          value: '36',
        },
      ],
      images: [
        {
          uri: '/428474001/428474001_cdfdcf1e-8245-4097-9732-141c08c79746',
          mediaType: 'image',
        },
        {
          uri: '/428474001/428474001_162dfa8e-794b-42b4-9319-5e71ca5d8aa1',
          mediaType: 'image',
        },
        {
          uri: '/428474001/428474001_10c8514e-45b5-4666-b8b5-3397d0f14913',
          mediaType: 'image',
        },
      ],
      isSellable: true,
    },
  ],
  seo: {
    description:
      'Alfredo Pauly Set: Shirt mit Blumendruck-Tuch und weitere Mode und Accessoires jetzt online bei HSE24.de bestellen!',
    title: 'Alfredo Pauly Set Shirt mit Tuch hier online',
  },
  categoryPath: 'Mode/Shirts & Tops/3-4 Arm',
  created: '2019-08-22T02:12:21+02:00',
  newInShop: '2020-03-09T00:00:00+01:00',
  tvOrTopDealInfo: 'HSE TREND',
  itemSource: ItemSource.REGULAR,
  countryVisibilities: [CountryCode.de, CountryCode.at],
};

export const allPaymentOptions: PaymentOption[] = [
  { enabled: true, method: PaymentMethod.paypal },
  { enabled: true, method: PaymentMethod.directDebit },
  { enabled: true, method: PaymentMethod.invoice },
  { enabled: true, method: PaymentMethod.cashOnDelivery },
  { enabled: true, method: PaymentMethod.creditCard },
  { enabled: true, method: PaymentMethod.installments },
  { enabled: true, method: PaymentMethod.installmentsFactoring },
  { enabled: true, method: PaymentMethod.sofort },
];

export const mockPaymentOptions: PaymentOption[] = [
  { enabled: true, method: PaymentMethod.paypal },
  { enabled: true, method: PaymentMethod.directDebit },
];

export const mockPaymentOptions2: PaymentOption[] = [
  { enabled: true, method: PaymentMethod.paypal },
  { enabled: true, method: PaymentMethod.directDebit },
  { enabled: true, method: PaymentMethod.invoice },
];

export const offerMockRsponse: OfferResponse = {
  offer: {
    items: [
      {
        id: 'ee4af7e5-6ddf-485f-b908-2a188736f4f7',
        baseProductNo: '426694',
        stockLevel: StockLevel.almostSoldOut,
        name: { long: 'Shirt mit Animaldruck-Einsatz' },
        brand: { brandName: 'Nala' },
        quantity: 1,
        availableStockAmount: 6,
        mediaUris: [
          {
            uri: '/426694002/426694002_eee14f17-cc45-4acf-989e-bcfee708a982',
            mediaType: 'image',
          },
        ],
        variant: {
          sku: '426694003',
          price: { value: 34.99 },
          dimensions: [
            { type: DimensionKind.color, value: 'schwarz' },
            { type: DimensionKind.size, value: '38' },
          ],
        },
        deliveryAddress: PostalDeliveryAddressExample,
        status: {
          type: 'SELLABLE',
        },
      },
    ],
    currency: Currency.euro,
    payment: { method: PaymentMethod.invoice },
    shippingCost: 5.95,
    totalProductPrice: 34.99,
    totalSavings: 0,
    totalPrice: 40.94,
    voucher: {
      value: 20,
      type: VirtualVoucherType.value,
      code: 'code',
    },
  },
  paymentOptions: [...mockPaymentOptions],
  changelog: [],
  installmentPlans: [
    {
      installmentCount: 3,
      installment: 15.63,
      finalInstallment: 15.63,
      interestRate: 13.12,
      totalInterest: 0.96,
      total: 46.89,
    },
    {
      installmentCount: 6,
      installment: 15.63,
      finalInstallment: 15.63,
      interestRate: 13.12,
      totalInterest: 0.96,
      total: 46.89,
    },
    {
      installmentCount: 9,
      installment: 15.63,
      finalInstallment: 15.63,
      interestRate: 13.12,
      totalInterest: 0.96,
      total: 46.89,
    },
  ],
};

export const searchSuggestionsMock: SearchSuggestionResponse[] = [
  {
    name: 'Strickware',
    type: 'category',
    image: '/430905001/430905001_3a981f3c-2cb9-4623-ac91-5f2ae9644421',
    hitCount: 0,
    searchParams: {
      query: '*',
      filters: [
        {
          name: 'CategoryPath',
          values: [
            {
              value: 'Mode/Strickware',
            },
          ],
          substring: false,
        },
      ],
      channel: 'dede',
    },
    attributes: {},
  },
  {
    name: 'Judith Williams',
    type: 'brand',
    image: '/430905001/430905001_3a981f3c-2cb9-4623-ac91-5f2ae9644421',
    hitCount: 0,
    searchParams: {
      query: '*',
      filters: [
        {
          name: 'Brand',
          values: [
            {
              value: 'Judith Williams',
            },
          ],
          substring: false,
        },
      ],
      channel: 'dede',
    },
    attributes: {},
  },
  {
    name: 'Judith Williams product',
    type: 'productName',
    image: '/430905001/430905001_3a981f3c-2cb9-4623-ac91-5f2ae9644421',
    hitCount: 0,
    searchParams: {
      query: '*',
      filters: [],
      channel: 'dede',
    },
    attributes: {
      masterId: '123456',
    },
  },
];

export const voucherSuggestionsResponse: VoucherSuggestionsResponse = {
  personalizedVouchers: [
    {
      code: 'code',
      value: 10,
      minValue: 10,
      maxValue: 100,
      validityEndTime: '2021-01-01T20:00:00Z',
      validityStartTime: '2020-01-01T20:00:00Z',
      restrictions: [],
      channelsValidity: [],
    },
    {
      code: 'code',
      percentage: 10,
      minValue: 10,
      validityEndTime: '2021-01-01T20:00:00Z',
      validityStartTime: '2020-01-01T20:00:00Z',
      restrictions: [{
        type: VoucherRestrictionType.category,
        name: 'Strickware',
        path: 'Mode/Strickware',
        mode: VoucherRestrictionMode.include,
      }],
      channelsValidity: [],
    },
    {
      code: 'code',
      value: 10,
      minValue: 10,
      maxValue: 100,
      validityEndTime: '2021-01-01T20:00:00Z',
      validityStartTime: '2020-01-01T20:00:00Z',
      restrictions: [{
        type: VoucherRestrictionType.brand,
        name: 'Eva Pfeffinger modernes Silberdesign',
        mode: VoucherRestrictionMode.include,
      }],
      channelsValidity: [],
    },
    {
      code: 'code',
      percentage: 10,
      minValue: 10,
      maxValue: 100,
      validityEndTime: '2021-01-01T20:00:00Z',
      validityStartTime: '2020-01-01T20:00:00Z',
      restrictions: [{
        type: VoucherRestrictionType.product,
        name: 'BH Modern Finesse W01',
        sku: '123456',
        mode: VoucherRestrictionMode.include,
      }],
      channelsValidity: [],
    },
  ],
  newCustomersVouchers: [
    {
      code: 'code',
      value: 10,
      minValue: 10,
      maxValue: 100,
      validityEndTime: '2021-01-01T20:00:00Z',
      validityStartTime: '2020-01-01T20:00:00Z',
      restrictions: [],
      channelsValidity: [],
    },
    {
      code: 'code',
      percentage: 10,
      minValue: 10,
      maxValue: 100,
      validityEndTime: '2021-01-01T20:00:00Z',
      validityStartTime: '2020-01-01T20:00:00Z',
      restrictions: [{
        type: VoucherRestrictionType.category,
        name: 'Strickware',
        path: 'Mode/Strickware',
        mode: VoucherRestrictionMode.include,
      }],
      channelsValidity: [],
    },
    {
      code: 'code',
      value: 10,
      minValue: 10,
      maxValue: 100,
      validityEndTime: '2021-01-01T20:00:00Z',
      validityStartTime: '2020-01-01T20:00:00Z',
      restrictions: [{
        type: VoucherRestrictionType.category,
        name: 'Strickware',
        path: 'Mode/Strickware',
        mode: VoucherRestrictionMode.include,
      }],
      channelsValidity: [],
    },
    {
      code: 'code',
      percentage: 10,
      minValue: 10,
      maxValue: 100,
      validityEndTime: '2021-01-01T20:00:00Z',
      validityStartTime: '2020-01-01T20:00:00Z',
      restrictions: [{
        type: VoucherRestrictionType.product,
        name: 'BH Modern Finesse W01',
        sku: '123456',
        mode: VoucherRestrictionMode.include,
      }],
      channelsValidity: [],
    },
  ],
  generalVouchers: [
    {
      code: 'code',
      value: 10,
      minValue: 10,
      maxValue: 100,
      validityEndTime: '2021-01-01T20:00:00Z',
      validityStartTime: '2020-01-01T20:00:00Z',
      restrictions: [],
      channelsValidity: [],
    },
    {
      code: 'code',
      percentage: 10,
      minValue: 10,
      maxValue: 100,
      validityEndTime: '2021-01-01T20:00:00Z',
      validityStartTime: '2020-01-01T20:00:00Z',
      restrictions: [{
        type: VoucherRestrictionType.category,
        name: 'Strickware',
        path: 'Mode/Strickware',
        mode: VoucherRestrictionMode.include,
      }],
      channelsValidity: [],
    },
    {
      code: 'code',
      value: 10,
      minValue: 10,
      maxValue: 100,
      validityEndTime: '2021-01-01T20:00:00Z',
      validityStartTime: '2020-01-01T20:00:00Z',
      restrictions: [{
        type: VoucherRestrictionType.brand,
        name: 'Eva Pfeffinger modernes Silberdesign',
        mode: VoucherRestrictionMode.include,
      }],
      channelsValidity: [],
    },
    {
      code: 'code',
      percentage: 10,
      minValue: 10,
      maxValue: 100,
      validityEndTime: '2021-01-01T20:00:00Z',
      validityStartTime: '2020-01-01T20:00:00Z',
      restrictions: [{
        type: VoucherRestrictionType.product,
        name: 'BH Modern Finesse W01',
        sku: '123456',
        mode: VoucherRestrictionMode.include,
      }],
      channelsValidity: [],
    },
  ],
};

export const redeemedVouchersResponse: RedeemedVouchersResponse = {
  vouchers: [
    {
      code: 'TVC12245',
      orderId: '123456',
      redeemedAt: '2022-04-12T02:37:33Z',
      voucherDetails: {
        code: 'TVC12245',
        percentage: 10,
        minValue: 10,
        maxValue: 100,
        validityStartTime: '2023-05-01T20:00:00Z',
        validityEndTime: '2023-05-01T20:00:00Z',
        restrictions: [{
          type: VoucherRestrictionType.category,
          name: 'Strickware',
          path: 'Mode/Strickware',
          mode: VoucherRestrictionMode.include,
        }],
        channelsValidity: [],
      },
    },
    {
      code: 'TVC12245',
      orderId: '123456',
      redeemedAt: '2022-04-12T02:37:33Z',
      voucherDetails: {
        code: 'TVC12245',
        percentage: 10,
        minValue: 10,
        maxValue: 100,
        validityStartTime: '2023-05-01T20:00:00Z',
        validityEndTime: '2023-05-01T20:00:00Z',
        restrictions: [{
          type: VoucherRestrictionType.category,
          name: 'Strickware',
          path: 'Mode/Strickware',
          mode: VoucherRestrictionMode.include,
        }],
        channelsValidity: [],
      },
    },
    {
      code: 'TVC12245',
      orderId: '123456',
      redeemedAt: '2022-04-12T02:37:33Z',
      voucherDetails: {
        code: 'TVC12245',
        percentage: 10,
        minValue: 10,
        maxValue: 100,
        validityStartTime: '2023-05-01T20:00:00Z',
        validityEndTime: '2023-05-01T20:00:00Z',
        restrictions: [{
          type: VoucherRestrictionType.category,
          name: 'Strickware',
          path: 'Mode/Strickware',
          mode: VoucherRestrictionMode.include,
        }],
        channelsValidity: [],
      },
    },
    {
      code: 'TVC12245',
      orderId: '123456',
      redeemedAt: '2022-04-12T02:37:33Z',
      voucherDetails: {
        code: 'TVC12245',
        percentage: 10,
        minValue: 10,
        maxValue: 100,
        validityStartTime: '2023-05-01T20:00:00Z',
        validityEndTime: '2023-05-01T20:00:00Z',
        restrictions: [{
          type: VoucherRestrictionType.category,
          name: 'Strickware',
          path: 'Mode/Strickware',
          mode: VoucherRestrictionMode.include,
        }],
        channelsValidity: [],
      },
    },
    {
      code: 'TVC12245',
      orderId: '123456',
      redeemedAt: '2022-04-12T02:37:33Z',
      voucherDetails: {
        code: 'TVC12245',
        percentage: 10,
        minValue: 10,
        maxValue: 100,
        validityStartTime: '2023-05-01T20:00:00Z',
        validityEndTime: '2023-05-01T20:00:00Z',
        restrictions: [{
          type: VoucherRestrictionType.category,
          name: 'Strickware',
          path: 'Mode/Strickware',
          mode: VoucherRestrictionMode.include,
        }],
        channelsValidity: [],
      },
    },
  ],
};

export const orderHistoryMock = [
  {
    id: '705719b1-26d1-49b6-8745-227ffbff0356',
    number: '1184526329',
    customerId: '3f455cdd-94fe-4135-bbaf-412d0dd61b48',
    externalCustomerId: '18586837',
    placedAt: '2021-02-10',
    subtotal: 84.97,
    shippingCosts: 0,
    voucher: undefined,
    total: 84.97,
    currency: 'EUR',
    orderStatus: {
      code: OrderStatusEnum.processing,
      label: 'In Bearbeitung',
    },
    items: [
      {
        sku: '336136',
        baseProductNumber: '336136',
        total: 29.99,
        brand: 'Judith Williams',
        title: 'JWC LLB EdP 100 ml',
        singlePrice: 29.99,
        quantity: 1,
        variants: [],
        mediaUris: [
          {
            uri: '/336136/336136_854d04ba-3719-41de-b839-1dd3c5652bef',
            mediaType: 'image',
          },
        ],
        deliveryAddress: PostalDeliveryAddressExample,
      },
      {
        sku: '290208',
        baseProductNumber: '290208',
        total: 34.99,
        brand: 'Judith Williams',
        title: 'JWC LLB Körperbutter 400 ml',
        singlePrice: 34.99,
        quantity: 1,
        variants: [],
        mediaUris: [
          {
            uri: '/290208/290208_81348c4d-4ad2-42c7-a02a-ec7b1c3c2f4c',
            mediaType: 'image',
          },
        ],
        deliveryAddress: PostalDeliveryAddressExample,
      },
      {
        sku: '341334017',
        baseProductNumber: '341334',
        total: 19.99,
        brand: 'Helena Vera',
        title: 'HV 6/8 Hose, Jeansoptik, 40/42, Rot',
        singlePrice: 19.99,
        quantity: 1,
        variants: [
          {
            type: 'COLOR',
            value: 'rot/blau',
          },
          {
            type: 'SIZE',
            value: '40/42',
          },
        ],
        mediaUris: [
          {
            uri: '/341334/341334_b96b75f1-3948-462c-bd66-66af54e8235c',
            mediaType: 'image',
          },
          {
            uri: '/341334/341334_d166121b-fcd2-4b9e-ac05-40ccc7dc36fc',
            mediaType: 'image',
          },
          {
            uri: '/341334/341334_e2b11470-e31a-445e-89cd-46b7e470fd16',
            mediaType: 'image',
          },
          {
            uri: '/341334/341334_9696dccb-83cd-400a-9ddf-7f95f5fc2336',
            mediaType: 'image',
          },
        ],
        deliveryAddress: PostalDeliveryAddressExample,
      },
    ],
    disChannel: '01',
    payment: { type: 'R' } as OrderPayment,
    isCancelable: true,
    customer: {
      salutation: Salutation.mister,
      firstName: 'test',
      lastName: 'test',
      email: 'test@gmail.com',
      dateOfBirth: '2002-04-02',
      phone: '+495885744',
      city: 'Gotham',
      zipCode: '12346',
      street: 'Street',
      streetNumber: '1',
      countryCode: CountryCode.de,
      createAccount: false,
      deliveryAddresses: [],
    }
  },
  {
    id: '6b962d77-b561-4bc1-b9a2-db4c17743a56',
    number: '1184524717',
    customerId: '3f455cdd-94fe-4135-bbaf-412d0dd61b48',
    externalCustomerId: '18586837',
    placedAt: '2020-10-20',
    subtotal: 128.15,
    shippingCosts: 0,
    voucher: undefined,
    total: 128.15,
    currency: 'EUR',
    orderStatus: {
      code: OrderStatusEnum.processing,
      label: 'In Bearbeitung',
    },
    items: [
      {
        sku: '359218036',
        baseProductNumber: '359218',
        total: 20.5,
        brand: 'Helena Vera',
        title: 'HV Ajourstrickjacke, 46, Türkis',
        singlePrice: 20.5,
        quantity: 1,
        variants: [
          {
            type: 'COLOR',
            value: 'Umbra',
          },
          {
            type: 'SIZE',
            value: '115',
          },
        ],
        mediaUris: [
          {
            uri: '/359218/359218_a0befef8-c53f-4f52-8b6f-791f82f43a1e',
            mediaType: 'image',
          },
          {
            uri: '/359218/359218_4a018465-99dd-4d98-a5bd-606fd9d9cd66',
            mediaType: 'image',
          },
          {
            uri: '/359218/359218_b9a01c5a-9dd3-43d4-bd4d-4b13e94a3551',
            mediaType: 'image',
          },
          {
            uri: '/359218/359218_5f3cf131-533e-4523-9a7a-24a89d1bfe21',
            mediaType: 'image',
          },
          {
            uri: '/359218/359218_337d10fe-d662-40ca-b172-d053df32d073',
            mediaType: 'image',
          },
        ],
      },
      {
        sku: '341334006',
        baseProductNumber: '341334',
        total: 20.5,
        brand: 'Helena Vera',
        title: 'HV 6/8 Hose, Jeansoptik, 36/38, Khaki',
        singlePrice: 20.5,
        quantity: 1,
        variants: [
          {
            type: 'COLOR',
            value: 'khaki/beige',
          },
          {
            type: 'SIZE',
            value: '36/38',
          },
        ],
        mediaUris: [
          {
            uri: '/341334/341334_b96b75f1-3948-462c-bd66-66af54e8235c',
            mediaType: 'image',
          },
          {
            uri: '/341334/341334_d166121b-fcd2-4b9e-ac05-40ccc7dc36fc',
            mediaType: 'image',
          },
          {
            uri: '/341334/341334_e2b11470-e31a-445e-89cd-46b7e470fd16',
            mediaType: 'image',
          },
          {
            uri: '/341334/341334_9696dccb-83cd-400a-9ddf-7f95f5fc2336',
            mediaType: 'image',
          },
        ],
      },
      {
        sku: '376008001',
        baseProductNumber: '376008',
        total: 20.5,
        brand: 'Clever',
        title: 'Clever Mop V2, beere',
        singlePrice: 20.5,
        quantity: 1,
        variants: [
          {
            type: 'COLOR',
            value: 'beere',
          },
        ],
        mediaUris: [
          {
            uri: '/376008/376008_db975366-6d9c-41f2-9c7d-a195a632396a',
            mediaType: 'image',
          },
          {
            uri: '/376008/376008_6553ce9e-f9a0-4428-b009-7145fa7b721d',
            mediaType: 'image',
          },
          {
            uri: '/376008/376008_46aba9da-484f-4cea-a27e-500ae6a345f8',
            mediaType: 'image',
          },
          {
            uri: '/376008/376008_20b58bdb-c84b-446e-ae11-0bc44207e946',
            mediaType: 'image',
          },
          {
            uri: '/376008/376008_d35ce9c8-efe1-4ab1-bc41-b7e262d7f4eb',
            mediaType: 'image',
          },
          {
            uri: '/376008/376008_5d08b4fc-300e-45f4-a1de-7b9d79a6a6ba',
            mediaType: 'image',
          },
          {
            uri: '/376008/376008_26225319-c74a-499d-b873-9edf41901a0c',
            mediaType: 'image',
          },
          {
            uri: '/376008/376008_e9e601d9-066d-4016-914c-1146f4066b1a',
            mediaType: 'image',
          },
          {
            uri: '/376008/376008_c656ad30-442f-4242-bd65-ead00309324e',
            mediaType: 'image',
          },
        ],
      },
      {
        sku: '290208',
        baseProductNumber: '290208',
        total: 35.89,
        brand: 'Judith Williams',
        title: 'JWC LLB Körperbutter 400 ml',
        singlePrice: 35.89,
        quantity: 1,
        variants: [],
        mediaUris: [
          {
            uri: '/290208/290208_81348c4d-4ad2-42c7-a02a-ec7b1c3c2f4c',
            mediaType: 'image',
          },
        ],
      },
      {
        sku: '336136',
        baseProductNumber: '336136',
        total: 30.76,
        brand: 'Judith Williams',
        title: 'JWC LLB EdP 100 ml',
        singlePrice: 30.76,
        quantity: 1,
        variants: [],
        mediaUris: [
          {
            uri: '/336136/336136_854d04ba-3719-41de-b839-1dd3c5652bef',
            mediaType: 'image',
          },
        ],
      },
    ],
    disChannel: '01',
    payment: { type: 'R' } as OrderPayment,
    isCancelable: true,
  },
  {
    id: '19a83af8-1e86-46cb-b1b8-a4fe6688fd00',
    number: '1184524722',
    customerId: '3f455cdd-94fe-4135-bbaf-412d0dd61b48',
    externalCustomerId: '18586837',
    placedAt: '2020-10-20',
    subtotal: 0,
    shippingCosts: 0,
    voucher: 0,
    total: 0,
    currency: 'EUR',
    orderStatus: {
      code: OrderStatusEnum.cancelled,
      label: 'Storniert',
    },
    items: [
      {
        sku: '341334006',
        baseProductNumber: '341334',
        total: 19.99,
        brand: 'Helena Vera',
        title: 'HV 6/8 Hose, Jeansoptik, 36/38, Khaki',
        singlePrice: 19.99,
        quantity: 1,
        variants: [
          {
            type: 'COLOR',
            value: 'khaki/beige',
          },
          {
            type: 'SIZE',
            value: '36/38',
          },
        ],
        mediaUris: [
          {
            uri: '/341334/341334_b96b75f1-3948-462c-bd66-66af54e8235c',
            mediaType: 'image',
          },
          {
            uri: '/341334/341334_d166121b-fcd2-4b9e-ac05-40ccc7dc36fc',
            mediaType: 'image',
          },
          {
            uri: '/341334/341334_e2b11470-e31a-445e-89cd-46b7e470fd16',
            mediaType: 'image',
          },
          {
            uri: '/341334/341334_9696dccb-83cd-400a-9ddf-7f95f5fc2336',
            mediaType: 'image',
          },
        ],
      },
      {
        sku: '365226',
        baseProductNumber: '365226',
        total: 24.99,
        title: '3in1 Spray Mop',
        singlePrice: 24.99,
        quantity: 1,
        variants: [],
        mediaUris: [
          {
            uri: '/365226/365226_6c819127-3a63-4501-b20f-3ecf35cc63db',
            mediaType: 'image',
          },
          {
            uri: '/365226/365226_aa3d9759-9a38-40fc-8e19-1c4c73169fdc',
            mediaType: 'image',
          },
          {
            uri: '/365226/365226_7baad785-04bb-466b-adfd-325a89cde958',
            mediaType: 'image',
          },
          {
            uri: '/365226/365226_d0258efc-b37d-4a3c-9c23-a8fd7b3080d7',
            mediaType: 'image',
          },
          {
            uri: '/365226/365226_330781bb-696c-4b8d-988b-faffca2e5f79',
            mediaType: 'image',
          },
        ],
      },
      {
        sku: '290208',
        baseProductNumber: '290208',
        total: 34.99,
        brand: 'Judith Williams',
        title: 'JWC LLB Körperbutter 400 ml',
        singlePrice: 34.99,
        quantity: 1,
        variants: [],
        mediaUris: [
          {
            uri: '/290208/290208_81348c4d-4ad2-42c7-a02a-ec7b1c3c2f4c',
            mediaType: 'image',
          },
        ],
      },
      {
        sku: '336136',
        baseProductNumber: '336136',
        total: 29.99,
        brand: 'Judith Williams',
        title: 'JWC LLB EdP 100 ml',
        singlePrice: 29.99,
        quantity: 1,
        variants: [],
        mediaUris: [
          {
            uri: '/336136/336136_854d04ba-3719-41de-b839-1dd3c5652bef',
            mediaType: 'image',
          },
        ],
      },
    ],
    disChannel: '01',
    payment: { type: 'R' } as OrderPayment,
    isCancelable: false,
  },
  {
    id: '19a83af8-1e86-46cb-b1b8-a4fe6688fd19',
    number: '1184524722',
    customerId: '3f455cdd-94fe-4135-bbaf-412d0dd61b48',
    externalCustomerId: '18586837',
    placedAt: '2020-10-20',
    subtotal: 0,
    shippingCosts: 0,
    voucher: 0,
    total: 0,
    currency: 'EUR',
    orderStatus: {
      code: OrderStatusEnum.cancelled,
      label: 'Storniert',
    },
    items: [
      {
        sku: '336136',
        baseProductNumber: '336136',
        total: 29.99,
        brand: 'Judith Williams',
        title: 'JWC LLB EdP 100 ml',
        singlePrice: 29.99,
        quantity: 1,
        variants: [],
        mediaUris: [
          {
            uri: '/336136/336136_854d04ba-3719-41de-b839-1dd3c5652bef',
            mediaType: 'image',
          },
        ],
      },
    ],
    disChannel: '01',
    payment: { type: 'R' } as OrderPayment,
    isCancelable: false,
    reklaFlag: {
      originalExternalOrderId: 'originalExternalOrderId1',
    },
  },
] as OrderHistoryResponse[];

export const aStockReminderRequest: StockReminderRequest = {
  email: 'customer@moro.com',
  sku: '123456789',
};

export const anOrderResponse: OrderResponse = {
  id: '486b27cb-e0e9-4864-aa4f-1593a5495f9f',
  customer: {
    salutation: Salutation.mister,
    firstName: 'test',
    lastName: 'test',
    email: 'test@gmail.com',
    dateOfBirth: '2002-04-02',
    phone: '+495885744',
    city: 'Gotham',
    zipCode: '12346',
    street: 'Street',
    streetNumber: '1',
    countryCode: CountryCode.de,
    createAccount: false,
  },
  items: [
    {
      id: 'string',
      name: {
        long: '',
      },
      variant: {
        price: { value: 10 },
        sku: 'string',
      },
      mediaUris: [
        {
          uri: 'url',
          mediaType: 'img',
        },
      ],
      quantity: 1,
      stockLevel: StockLevel.available,
    },
  ],
  currency: Currency.euro,
  deliveryAddress: {
    type: DeliveryAddressType.Postal,
    salutation: Salutation.miss,
    firstName: 'Sue',
    lastName: 'Müller',
    street: 'Schleißheimerstr.',
    streetNumber: '1',
    zipCode: '56789',
    city: 'Schleißheimerstadt',
    addressAddition: undefined,
    countryCode: CountryCode.de,
    postNumber: '1',
  },
  payment: { method: PaymentMethod.invoice },
  delivery: DeliveryMethod.dhl,
  totalProductPrice: 34.99,
  totalSavings: 0,
  totalPrice: 40.94,
};

export const editedOfferMockResponse: EditedOffer = {
  editedOrderId: '1234567',
  items: [
    {
      id: 'ee4af7e5-6ddf-485f-b908-2a188736f4f7',
      baseProductNo: '426694',
      stockLevel: StockLevel.almostSoldOut,
      name: { long: 'Shirt mit Animaldruck-Einsatz' },
      brand: { brandName: 'Nala' },
      quantity: 1,
      availableStockAmount: 6,
      mediaUris: [
        {
          uri: '/426694002/426694002_eee14f17-cc45-4acf-989e-bcfee708a982',
          mediaType: 'image',
        },
      ],
      variant: {
        sku: '426694003',
        price: { value: 34.99 },
        dimensions: [
          { type: DimensionKind.color, value: 'schwarz' },
          { type: DimensionKind.size, value: '38' },
        ],
      },
      deliveryAddress: PostalDeliveryAddressExample,
      status: {
        type: 'SELLABLE',
      },
    },
  ],
  currency: Currency.euro,
  payment: { method: PaymentMethod.invoice },
  shippingCost: { amount: 5.95 },
  totalProductPrice: 34.99,
  totalSavings: 0,
  totalPrice: 40.94,
  paymentOptions: [
    { enabled: true, method: PaymentMethod.paypal },
    { enabled: true, method: PaymentMethod.directDebit },
  ],
  voucher: {
    value: 20,
    type: VirtualVoucherType.value,
    code: 'code',
  },
  installmentPlans: [
    {
      installmentCount: 3,
      installment: 15.63,
      finalInstallment: 15.63,
      interestRate: 13.12,
      totalInterest: 0.96,
      total: 46.89,
    },
    {
      installmentCount: 6,
      installment: 15.63,
      finalInstallment: 15.63,
      interestRate: 13.12,
      totalInterest: 0.96,
      total: 46.89,
    },
    {
      installmentCount: 9,
      installment: 15.63,
      finalInstallment: 15.63,
      interestRate: 13.12,
      totalInterest: 0.96,
      total: 46.89,
    },
  ],
  deliveryAddress: {
    id: '1',
    type: DeliveryAddressType.Postal,
    salutation: Salutation.miss,
    firstName: 'Sue',
    lastName: 'Müller',
    street: 'Schleißheimerstr.',
    streetNumber: '1',
    zipCode: '56789',
    city: 'Schleißheimerstadt',
    countryCode: CountryCode.de,
  },
};

export const editedOrderMockResponse: EditedOrder = {
  originalOrder: orderHistoryMock[0],
  editedOffer: editedOfferMockResponse,
};

const editedChOffer: EditedOffer = {
  ...editedOfferMockResponse,
  items: [
    editedOfferMockResponse.items[0],
    { ...editedOfferMockResponse.items[0], number: '10' },
    { ...editedOfferMockResponse.items[0], number: '20' },
  ],
  currency: Currency.chf
};

const b2bChCustomer: Customer = {
  id: '26f6e7b2-1a3a-4b01-97b7-93d58a267ab5',
  firstName: 'MS ab 8/15 umfirmiert MS Direct Group AG',
  lastName: 'Mail Service AG',
  salutation: Salutation.mister,
  dateOfBirth: '08.08.2019',
  deliveryAddresses: [{
    externalAddressId: '11199999',
    salutation: Salutation.mister,
    firstName: 'MS ab 8/15 umfirmiert MS Direct Group AG',
    lastName: 'Mail Service AG',
    street: 'Furstenlandstrasse',
    addressAddition: '1',
    streetNumber: '35',
    zipCode: '9001',
    city: 'St.Galllen',
    countryCode: CountryCode.ch,
    isDefault: true,
    fromInvoiceAddress: true,
    type: DeliveryAddressType.Postal,
  }],
  phoneNumbers: [],
  origin: '',
  externalCustomerId: '11199999',
  originalPhoneNumber: '',
  phoneNumber: '',
  billingAddress: {
    street: 'Furstenlandstrasse',
    addressAddition: '1',
    streetNumber: '35',
    zipCode: '9001',
    city: 'St.Galllen',
    countryCode: CountryCode.ch
  },
};
export const editedChOrder: EditedOrder = {
  originalOrder: {
    ...orderHistoryMock[0],
    pregeneratedErpNumber: 16000000001,
    currency: Currency.chf,
    customer: b2bChCustomer,
    customerId: b2bChCustomer.id,
    isEditable: true,
  },
  editedOffer: editedChOffer,
};

export const offerItemExample = {
  id: '428474001',
  sku: '428474001',
  baseProductNo: '428474001',
  mediaUris: [{ uri: '/406123002/406123002_3504390c-9b5c-4998-953b-f66ac38c3428', mediaType: 'image' }],
  name: { long: 'Shirt mit Tuch' },
  brand: { brandName: 'Alfredo Pauly' },
  quantity: 2,
  stockLevel: StockLevel.available,
  variant: {
    price: {
      base: {
        amount: 1,
        unit: 'kg',
        value: 49.975,
      },
      value: 9.99,
      reference: {
        value: 9.9,
        saving: 0.09,
      },
    },
    sku: '428474001',
    dimensions: [
      {
        type: DimensionKind.size,
        value: '36',
      },
      {
        type: DimensionKind.color,
        value: 'marine',
      },
    ],
  },
  itemSource: ItemSource.REGULAR,
  categoryPath: 'Mode/Shirts & Tops/3-4 Arm',
} as OfferItem;

export const productImpressionsViewsMock: ProductImpressionsViewItem = {
  name: '428474 | Shirt mit Tuch',
  list: 'TV | HSE',
  id: '428474',
  price: 59.99,
  brand: 'Alfredo Pauly',
  category: 'Mode/Shirts & Tops/3-4 Arm',
  variant: 'COLOR:marine|SIZE:36',
  position: 0,
  dimension10: '428474001',
  dimension2: 'Main Product',
  dimension8: 'not available',
  dimension9: 'Shopping-Preis',
  dimension12: 'TV',
};

export const productDetailsViewsMock: ProductDetailsViews = {
  detail: {
    actionField: {
      list: '428474 | Shirt mit Tuch',
      action: 'detail',
    },
    products: [{
      name: '428474 | Shirt mit Tuch',
      id: '428474',
      price: '59.99',
      brand: 'Alfredo Pauly',
      category: 'Mode/Shirts & Tops/3-4 Arm',
      variant: 'COLOR:marine|SIZE:36',
      dimension10: '428474',
      dimension2: 'Main Product',
      dimension8: 'not available',
      dimension9: 'Shopping-Preis',
      dimension12: 'TV',
    }],
  },
};

export const productDetailsViewsNoListMock: ProductDetailsViews = {
  detail: {
    actionField: {
      action: 'detail',
    },
    products: [{
      name: '428474 | Shirt mit Tuch',
      id: '428474',
      price: '59.99',
      brand: 'Alfredo Pauly',
      category: 'Mode/Shirts & Tops/3-4 Arm',
      variant: 'COLOR:marine|SIZE:36',
      dimension10: '428474',
      dimension2: 'Main Product',
      dimension8: 'not available',
      dimension9: 'Shopping-Preis',
      dimension12: 'TV',
    }],
  },
};

export const inflightOrderResponse: InflightOrder = {
  id: '1234',
  code: 500,
  createdAt: '2021-02-17T14:00:00Z',
  order: {
    basketId: '8edbed41-5cea-4cd8-9285-d6a8dd4b33ad',
    baseProductNo: '428474',
    customer: {
      customerNumber: '12345678',
      phone: '+49 777 777 777',
      dateOfBirth: '1940-07-13',
    },
    voucherCode: '564',
    paymentMethod: PaymentMethod.invoice,
    step: Steps.INVALID_ORDER,
  },
};

export const anEditedOrder = {
  id: 'ce118b6e-d8e1-11e7-9296-cec278b6b50a',
  changes: [],
  customer: {
    salutation: Salutation.mister,
    firstName: 'test',
    lastName: 'test',
    email: 'test@gmail.com',
    dateOfBirth: '2002-04-02',
    phone: '+495885744',
    city: 'Gotham',
    zipCode: '12346',
    street: 'Street',
    streetNumber: '1',
    countryCode: CountryCode.de,
    createAccount: false,
  } as CustomerResponse,
  editedBy: 'agent',
  items: [],
  originalOrder: {
    isCancelable: true,
    isEditable: true,
    id: 'f3d7677d-212c-4ae4-8838-c84ee1abcac2',
    number: '1100154455',
    customerId: '76357528-5abc-4905-adc7-255a9334f742',
    placedAt: '2020-02-16',
    subtotal: 20.000,
    shippingCosts: 5.950,
    voucher: { value: 0.000, employerDiscount: 0.000 },
    total: 25.950,
    currency: Currency.euro,
    orderStatus: {
      code: OrderStatusEnum.processing,
      label: 'In Bearbeitung',
    },
    payment: { type: 'Invoice' },
    disChannel: '01',
    deliveryAddress: PostalDeliveryAddressExample,
    items: [],
  } as OrderHistoryResponse,
  status: {
    status: EditedOrderStatusTypes.ACCEPTED,
    timestamp: '',
  },
} as EditedOrderResponse;

export const mockProduct: Product = {
  id: '123434',
  masterValues: {
    name: 'test name',
    shortDescription: 'test short description',
    description: 'test description',
    brand: 'test brand',
    brandUrl: 'test brand url',
    additionalInformation: 'test additionalInformation',
    freeShipping: true,
    categoryPath: [['test categoryPath']],
    categoryUrl: [['test categoryUrl']],
    masterProductNumber: '12345',
    salesdrivers: ['TEST', 'SALESDRIVER'],
  },
  variantValues: [{
    price: 9.99,
    productNumber: '428474',
    basePrice: 9.99,
    basePriceAmount: 9.99,
    basePriceUnit: 'EUR',
    imageURL: 'URL',
    priceLabel: 'priceLabel',
    referencePrice: 9.99,
    referencePriceLabel: 'referencePriceLabel',
    referencePriceSaving: 9.99,
    referencePriceSavingPercent: 9.99,
    referencePriceType: 'referencePriceType',
    status: ProductStatus.Sellable,
    colorFamilies: [],
    sIZE: [],
    cOLOR: [],
  }],
};

export const mockStatusHistoryEntries: StatusHistoryEntry[] = [
  {
    status: Status.accepted,
    timestamp: '1223332145',
    backofficeAgentName: 'backofficeAgentName',
  },
  {
    status: Status.rejected,
    timestamp: '1223332145',
  },
];

export const savedCustomers: SavedCustomer[] = [
  {
    id: '51480340-45b6-468a-b843-730932f34508',
    customer: {
      id: '56c7c1be-9d15-48ad-aec6-7a3296ff4b99',
      externalCustomerId: '11369298',
      salutation: Salutation.miss,
      firstName: 'test001',
      lastName: 'test001',
      dateOfBirth: '2000-10-10',
      email: 'test001@hse24.de',
      phoneNumbers: [],
      billingAddress: {
        type: AddressType.Billing,
        id: '7acffa46-0a1f-461c-9b6c-366a117533ac',
        street: 'Hechelstr.',
        streetNumber: '16',
        zipCode: '13403',
        city: 'Berlin',
        countryCode: CountryCode.de,
      },
      deliveryAddresses: [],
      origin: 'asdsa',
    },
    loginTime: '2022-02-21T10:36:56.937Z',
    guestCustomerConsentsStepIsSkipped: true,
  },
  {
    id: 'bd6a0a44-34ae-4356-bb41-17cbf5e4edbf',
    customer: {
      id: 'fd9b02c3-b438-413b-a865-0dc16420f1fa',
      externalCustomerId: '11370878',
      salutation: Salutation.mister,
      firstName: 'asdfasf',
      lastName: 'asfasd',
      dateOfBirth: '1990-01-01',
      email: 'fananos143@activesniper.com',
      phoneNumbers: [],
      billingAddress: {
        type: AddressType.Billing,
        id: 'aee87fbe-e1a0-4383-9c22-566ba6c966d0',
        street: 'asda',
        streetNumber: '1',
        zipCode: '27432',
        city: 'Basdahl',
        countryCode: CountryCode.de,
      },
      deliveryAddresses: [],
      allowUpsells: true,
      origin: 'asdsa',
    },
    loginTime: '2022-02-21T10:36:48.590Z',
  },
  {
    id: '7e4b9245-f6b7-4340-9d8e-df48a0bc6c42',
    customer: {
      id: '',
      salutation: Salutation.mister,
      firstName: 'asd',
      lastName: 'qwe',
      email: '',
      dateOfBirth: '03.02.2004',
      phoneNumber: '',
      billingAddress: {
        street: 'sadwqe',
        careOf: '',
        streetNumber: '123',
        addressAddition: '',
        zipCode: '12323',
        city: '',
        countryCode: CountryCode.de,
      },
      origin: '',
      externalCustomerId: '',
      ignoreAddressCheck: true,
      deliveryAddresses: [],
      phoneNumbers: [],
    },
    loginTime: '2022-02-21T09:54:54.989Z',
  },
];

export const waitlistHistoryMockResponse: WaitlistHistoryItemsResponse = {
  items: [
    {
      id: '00000000-0000-0000-00000-xxxxxxxxxx',
      externalCustomerId: '123456',
      status: {
        timestamp: '2022-05-09T10:21:38.624220Z',
        type: WaitlistItemStatusType.CREATED,
        userAgentName: 'agent.x',
      },
      details: {
        customer: {
          crmPartnerId: '123456',
          dateOfBirth: '1975-01-20',
          dplCustomerId: '0000000-0000-xxxxx-00000-00000',
          salutation: Salutation.miss,
          firstName: 'Foo',
          lastName: 'Bar',
          street: 'baz',
          streetNumber: 'bat',
          zipCode: '12345',
          countryCode: CountryCode.de,
          city: 'Berlin',
        },
        deliveryAddress: PostalDeliveryAddressExample,
        payment: { method: PaymentMethod.invoice },
        product: {
          quantity: 1,
          sku: '123434',
          price: 1,
        },
        salesChannel: DEFAULT_SALES_CHANNEL,
        salesOffice: DEFAULT_SALES_OFFICE,
      },
      statusesHistory: {
        statuses: [
          {
            timestamp: '2022-05-09T10:21:38.624220Z',
            type: WaitlistItemStatusType.CREATED,
            userAgentName: 'agent.x',
          },
        ],
      },
      orderCreatedAt: undefined,
    },
  ],
  size: 10,
  offset: 1,
  total: 1
};

export const customerBankingDetailsStateMock = {
  isExisted: false,
  accountHolder: 'test test',
  bankName: '',
  iban: '',
  usesPrefilledIban: false,
  ibanValid: false,
  firstTime: true,
  ibanError: undefined,
};

export const orderPreCheckResultMock: OrderPreCheckResponse = {
  paymentOptions: DEFAULT_PAYMENTS,
  deliveryAddress: { isValid: true },
  invoiceAddress: { isValid: true },
};

export const subscriptionItemMock: SubscriptionItem = {
  id: 'd43bfb08-f398-4dce-87f8-2d66d08e3806',
  contractNumber: 1,
  externalCustomerId: '123456',
  status: {
    type: SubscriptionItemStatusType.ACTIVE,
    agentUsername: 'name',
    timestamp: '2022-07-04T10:45:05.433753Z',
  },
  product: {
    baseProductNo: '12345',
    variant: {
      dimensions: [
        {
          type: DimensionKind.color,
          value: 'red',
        },
      ],
      price: {
        value: 120,
        currencyCode: Currency.euro,
      },
      sku: '12345001',
      imageSrc: 'img',
      deliveryTime: {
        deliveryMinDays: 5,
        deliveryDays: 10,
      },
    },
    name: {
      long: 'Kuders Gartenparadies Dünger, 1 Liter',
    },
    baseImageUri: 'img',
    brand: {
      brandName: 'brandName',
    },
    description: 'description',
  },
  details: {
    customer: {
      salutation: Salutation.mister,
      firstName: 'first_name',
      lastName: 'last_name',
      street: 'street',
      streetNumber: '1',
      addressAddition: 'address_addition',
      zipCode: '12300',
      city: 'city',
      countryCode: CountryCode.de,
      email: 'email@hse.de',
      phone: '040445555555',
      dateOfBirth: '1990-01-01',
      dplCustomerId: 'd43bfb08-f398-4dce-87f8-2d66d08e3806',
      crmPartnerId: '123456',
      careOf: 'careOf',
      reception: ReceptionProvider.ANALOG_CABLE,
      type: CustomerType.CREATOR,
    },
    product: {
      sku: '123456001',
      quantity: 1,
    },
    payment: {
      method: PaymentMethod.creditCard,
    },
    deliveryAddress: {
      type: DeliveryAddressType.Postal,
      id: '11111',
      externalAddressId: '1111',
      salutation: Salutation.miss,
      firstName: 'first_name',
      lastName: 'last_name',
      street: 'street',
      streetNumber: '1',
      addressAddition: 'address_addition',
      zipCode: '12300',
      city: 'city',
      countryCode: CountryCode.de,
      careOf: 'careOf',
    },
    salesChannel: '04',
    salesOffice: '4405',
    sourceChannel: 'ECOA',
    shippingCost: 0,
  },
  aboInfo: {
    rotation: 'ONE_MONTH',
    startIn: 'TODAY',
    aboNumber: '213456',
    nextOrderDate: '2022-07-04',
  },
};

export const stockMock = [

  { sku: '450445001', amount: 200 },
  { sku: '450445002', amount: 200 },
  { sku: '450445003', amount: 200 },
  { sku: '450445004', amount: 200 },
  { sku: '450445005', amount: 200 },
  { sku: '450445006', amount: 200 },
  { sku: '450445007', amount: 200 },
  { sku: '450445008', amount: 200 },
  { sku: '450445009', amount: 200 },
  { sku: '450445010', amount: 200 },
  { sku: '450445011', amount: 200 },
  { sku: '450445012', amount: 200 },
  { sku: '450445013', amount: 200 },
  { sku: '450445014', amount: 200 },
  { sku: '450445015', amount: 200 },
  { sku: '450445016', amount: 200 },
  { sku: '450445017', amount: 200 },
  { sku: '450445018', amount: 200 },
  { sku: '450445019', amount: 200 },
  { sku: '450445020', amount: 200 },
  { sku: '450445021', amount: 200 },
  { sku: '450445022', amount: 200 },

  { sku: '449753001', amount: 0 },
  { sku: '449753002', amount: 200 },
  { sku: '449753003', amount: 200 },
  { sku: '449753004', amount: 200 },
  { sku: '449753005', amount: 200 },
  { sku: '449753006', amount: 200 },
  { sku: '449753007', amount: 200 },
  { sku: '449753008', amount: 200 },
  { sku: '449753009', amount: 200 },
  { sku: '449753010', amount: 200 },
  { sku: '449753011', amount: 200 },
  { sku: '449753012', amount: 200 },
  { sku: '449753013', amount: 200 },
  { sku: '449753014', amount: 200 },
  { sku: '449753015', amount: 200 },
  { sku: '449753016', amount: 200 },
  { sku: '449753017', amount: 200 },
  { sku: '449753018', amount: 200 },
  { sku: '449753019', amount: 200 },
  { sku: '449753020', amount: 200 },
  { sku: '449753021', amount: 200 },
  { sku: '449753022', amount: 0 },

  { sku: '450391001', amount: 1 },
  { sku: '450391002', amount: 1 },
  { sku: '450391003', amount: 1 },
  { sku: '450391004', amount: 1 },
  { sku: '450391005', amount: 1 },
  { sku: '450391006', amount: 1 },
  { sku: '450391007', amount: 1 },
  { sku: '450391008', amount: 1 },
  { sku: '450391009', amount: 1 },
  { sku: '450391010', amount: 1 },
  { sku: '450391011', amount: 1 },
  { sku: '450391012', amount: 1 },
  { sku: '450391013', amount: 1 },
  { sku: '450391014', amount: 1 },
  { sku: '450391015', amount: 1 },
  { sku: '450391016', amount: 1 },
  { sku: '450391017', amount: 1 },
  { sku: '450391018', amount: 1 },
  { sku: '450391019', amount: 1 },
  { sku: '450391020', amount: 1 },
  { sku: '450391021', amount: 1 },
  { sku: '450391022', amount: 1 },

  { sku: '450424001', amount: 0 },
  { sku: '450424002', amount: 0 },
  { sku: '450424003', amount: 0 },
  { sku: '450424004', amount: 0 },
  { sku: '450424005', amount: 0 },
  { sku: '450424006', amount: 0 },
  { sku: '450424007', amount: 0 },
  { sku: '450424008', amount: 0 },
  { sku: '450424009', amount: 0 },
  { sku: '450424010', amount: 0 },
  { sku: '450424011', amount: 0 },
  { sku: '450424012', amount: 0 },
  { sku: '450424013', amount: 0 },
  { sku: '450424014', amount: 0 },
  { sku: '450424015', amount: 0 },
  { sku: '450424016', amount: 0 },
  { sku: '450424017', amount: 0 },
  { sku: '450424018', amount: 0 },
  { sku: '450424019', amount: 0 },
  { sku: '450424020', amount: 0 },
  { sku: '450424021', amount: 0 },
  { sku: '450424022', amount: 0 },

  { sku: '434450001', amount: 807 },
  { sku: '434450002', amount: 807 },
  { sku: '434450003', amount: 807 },
  { sku: '434450004', amount: 807 },
  { sku: '434450005', amount: 807 },

  { sku: '450360001', amount: 0 },
  { sku: '450360002', amount: 807 },
  { sku: '450360003', amount: 807 },
  { sku: '450360004', amount: 807 },
  { sku: '450360005', amount: 807 },

  { sku: '450121001', amount: 1 },
  { sku: '450121002', amount: 1 },
  { sku: '450121003', amount: 1 },
  { sku: '450121004', amount: 1 },
  { sku: '450121005', amount: 1 },

  { sku: '449175001', amount: 0 },
  { sku: '449175002', amount: 0 },
  { sku: '449175003', amount: 0 },
  { sku: '449175004', amount: 0 },
  { sku: '449175005', amount: 0 },

  { sku: '453734', amount: 0 },
  { sku: '453732', amount: 20 },
  { sku: '453738', amount: 40 },
];

export const subscriptionOverviewResponseMock: SubscriptionsOverviewResponse = {
  result: [
    {
      ...subscriptionItemMock,
      ordersCount: 1,
      createdAt: '2023-02-20',
      updatedAt: '2023-02-20',
    },
  ],
  size: 10,
  page: 1,
  total: 100,
};

export const failedStatusChange: SubscriptionChangeItem = {
  subscriptionId: '3cb789fe-1497-4ff5-8b9f-29a9ae740777',
  createdAt: '2022-07-05T10:45:05.433753Z',
  changeEvent: {
    type: SubscriptionChangeEventType.STATUS_UPDATED,
    newStatus: {
      type: SubscriptionItemStatusType.FAILED,
      timestamp: '2022-07-04T10:45:05.433753Z',
    },
    oldStatus: {
      type: SubscriptionItemStatusType.CREATED,
      agentUsername: 'useragent',
      timestamp: '2022-07-04T10:45:05.433753Z',
    },
  },
};
export const inProgressStatusChange: SubscriptionChangeItem = {
  subscriptionId: '3cb789fe-1497-4ff5-8b9f-29a9ae740777',
  createdAt: '2022-07-06T10:45:05.433753Z',
  changeEvent: {
    type: SubscriptionChangeEventType.STATUS_UPDATED,
    newStatus: {
      type: SubscriptionItemStatusType.IN_PROGRESS,
      failure: {
        title: 'title',
        details: 'Stock is not enough',
      },
      timestamp: '2022-07-04T10:45:05.433753Z',
    },
    oldStatus: {
      type: SubscriptionItemStatusType.CREATED,
      agentUsername: 'useragent',
      timestamp: '2022-07-04T10:45:05.433753Z',
    },
  },
};

export const subscriptionChangesHistoryMockResponse: SubscriptionChangesHistoryResponse = {
  page: 1,
  size: 3,
  changes: [
    {
      subscriptionId: '3cb789fe-1497-4ff5-8b9f-29a9ae740777',
      createdAt: '2022-07-04T10:45:05.433753Z',
      changeEvent: {
        type: SubscriptionChangeEventType.STATUS_UPDATED,
        newStatus: {
          type: SubscriptionItemStatusType.CREATED,
          agentUsername: 'useragent',
          timestamp: '2022-07-04T10:45:05.433753Z',
        },
        oldStatus: {
          type: SubscriptionItemStatusType.CANCELED,
          agentUsername: 'useragent',
          timestamp: '2022-07-04T10:45:05.433753Z',
        },
      },
    },
    {
      subscriptionId: '3cb789fe-1497-4ff5-8b9f-29a9ae740777',
      createdAt: '2022-07-04T10:45:05.433753Z',
      changeEvent: {
        type: SubscriptionChangeEventType.STATUS_UPDATED,
        newStatus: {
          type: SubscriptionItemStatusType.CREATED,
          agentUsername: 'useragent',
          timestamp: '2022-07-04T10:45:05.433753Z',
          orderId: 'some-order-id',
        },
        oldStatus: {
          type: SubscriptionItemStatusType.ACTIVE,
          agentUsername: 'useragent',
          timestamp: '2022-07-04T10:45:05.433753Z',
        },
      },
    },
    {
      subscriptionId: '3cb789fe-1497-4ff5-8b9f-29a9ae740777',
      createdAt: '2022-07-04T10:45:05.433753Z',
      changeEvent: {
        type: SubscriptionChangeEventType.STATUS_UPDATED,
        newStatus: {
          type: SubscriptionItemStatusType.CANCELED,
          agentUsername: 'useragent',
          timestamp: '2022-07-04T10:45:05.433753Z',
        },
        oldStatus: {
          type: SubscriptionItemStatusType.CREATED,
          agentUsername: 'useragent',
          timestamp: '2022-07-04T10:45:05.433753Z',
        },
      },
    },
    {
      subscriptionId: '3cb789fe-1497-4ff5-8b9f-29a9ae740777',
      createdAt: '2022-07-04T10:45:05.433753Z',
      changeEvent: {
        type: SubscriptionChangeEventType.QUANTITY_UPDATED,
        oldQuantity: 1,
        newQuantity: 2,
        agentUsername: 'useragent',
      },
    },
    {
      subscriptionId: '3cb789fe-1497-4ff5-8b9f-29a9ae740777',
      createdAt: '2022-07-04T10:45:05.433753Z',
      changeEvent: {
        type: SubscriptionChangeEventType.ROTATION_UPDATED,
        oldRotation: SubscriptionRotation.FOUR_MONTHS,
        newRotation: SubscriptionRotation.FIVE_MONTHS,
        agentUsername: 'useragent',
      },
    },
    {
      subscriptionId: '3cb789fe-1497-4ff5-8b9f-29a9ae740777',
      createdAt: '2022-07-04T10:45:05.433753Z',
      changeEvent: {
        type: SubscriptionChangeEventType.DELIVERY_DATE_UPDATED,
        oldDeliveryDate: '2030-10-10',
        newDeliveryDate: '2030-11-10',
        agentUsername: 'useragent',
        note: 'note',
      },
    },
    {
      subscriptionId: '3cb789fe-1497-4ff5-8b9f-29a9ae740777',
      createdAt: '2022-07-05T10:45:05.433753Z',
      changeEvent: {
        type: SubscriptionChangeEventType.PAYMENT_UPDATED,
        oldPayment: {
          method: PaymentMethod.cashOnDelivery,
        },
        newPayment: {
          method: PaymentMethod.installments,
        },
        agentUsername: 'useragent',
      },
    },
    {
      subscriptionId: '3cb789fe-1497-4ff5-8b9f-29a9ae740777',
      createdAt: '2022-07-05T10:45:05.433753Z',
      changeEvent: {
        type: SubscriptionChangeEventType.DELIVERY_ADDRESS_UPDATED,
        oldAddress: mockAddress,
        newAddress: PostalDeliveryAddressExample,
        agentUsername: 'useragent',
      },
    },
    {
      subscriptionId: '3cb789fe-1497-4ff5-8b9f-29a9ae740777',
      createdAt: '2022-07-04T10:45:05.433753Z',
      changeEvent: {
        type: SubscriptionChangeEventType.STATUS_UPDATED,
        newStatus: {
          type: SubscriptionItemStatusType.ACTIVE,
          agentUsername: 'useragent',
          timestamp: '2022-07-04T10:45:05.433753Z',
        },
        oldStatus: {
          type: SubscriptionItemStatusType.CREATED,
          agentUsername: 'useragent',
          timestamp: '2022-07-04T10:45:05.433753Z',
        },
      },
    },
    {
      subscriptionId: '3cb789fe-1497-4ff5-8b9f-29a9ae740777',
      createdAt: '2022-07-04T10:45:05.433753Z',
      changeEvent: {
        type: SubscriptionChangeEventType.STATUS_UPDATED,
        newStatus: {
          type: SubscriptionItemStatusType.ACTIVE,
          agentUsername: 'useragent',
          timestamp: '2022-07-04T10:45:05.433753Z',
          orderId: 'some-order-id-2',
        },
        oldStatus: {
          type: SubscriptionItemStatusType.CREATED,
          agentUsername: 'useragent',
          timestamp: '2022-07-04T10:45:05.433753Z',
        },
      },
    },
    {
      subscriptionId: '3cb789fe-1497-4ff5-8b9f-29a9ae740777',
      createdAt: '2022-07-04T10:45:05.433753Z',
      changeEvent: {
        type: SubscriptionChangeEventType.STATUS_UPDATED,
        newStatus: {
          type: SubscriptionItemStatusType.ACTIVE,
          agentUsername: 'useragent',
          timestamp: '2022-07-04T10:45:05.433753Z',
          orderId: 'some-order-id-2',
        }
      },
    },
    {
      subscriptionId: '3cb789fe-1497-4ff5-8b9f-29a9ae740777',
      createdAt: '2022-07-04T10:45:05.433753Z',
      changeEvent: {
        type: SubscriptionChangeEventType.SUBSCRIPTION_PRODUCT_NUMBER_UPDATED,
        oldNumber: '543214',
        newNumber: '123454',
        agentUsername: 'useragent',
      },
    },
    {
      subscriptionId: '3cb789fe-1497-4ff5-8b9f-29a9ae740777',
      createdAt: '2022-07-04T10:45:05.433753Z',
      changeEvent: {
        type: SubscriptionChangeEventType.DELIVERY_PRODUCT_NUMBER_UPDATED,
        oldNumber: '981724',
        newNumber: '123455',
        agentUsername: 'useragent',
      },
    },
    failedStatusChange,
    inProgressStatusChange,
  ],
};

export const subscriptionReportMockResponse: SubscriptionReportResponse = {
  page: 1,
  size: 10,
  currentMonthFloorSales: 651020,
  previousMonthFloorSales: 593200,
  floorSalesVariation: 1.59,
  totalActiveSubscriptions: 11023,
  totalSubscribedProducts: 153,
  monthlyStockStatus: [
    { month: 'JANUARY', hasSufficientStock: true, productsInShortage: 100 },
    { month: 'FEBRUARY', hasSufficientStock: true, productsInShortage: 100 },
    { month: 'MARCH', hasSufficientStock: true, productsInShortage: 100 },
    { month: 'APRIL', hasSufficientStock: true, productsInShortage: 100 },
    { month: 'MAY', hasSufficientStock: true, productsInShortage: 100 },
    { month: 'JUNE', hasSufficientStock: false, productsInShortage: 5 },
    { month: 'JULY', hasSufficientStock: false, productsInShortage: 7 },
    { month: 'AUGUST', hasSufficientStock: false, productsInShortage: 12 },
  ],
  items: [
    {
      id: 'bd6a0a44-34ae-4356-bb41-17cbf5e4edbf',
      deliveryProductNo: 9500233340,
      subscriptionProductNo: 281525,
      productName: 'Vitamin B Komplex, 90 Kapseln',
      price: 27.99,
      currency: Currency.euro,
      subscriptionsCount: 231,
      currentStock: 420,
      productStockStatuses: [
        { dueDate: '2023-01-31T10:45:05.433753Z', necessaryStock: 15, hasSufficientStock: false },
        { dueDate: '2023-02-28T10:45:05.433753Z', necessaryStock: 87, hasSufficientStock: false },
        { dueDate: '2023-03-31T10:45:05.433753Z', necessaryStock: 156, hasSufficientStock: false },
        { dueDate: '2023-04-31T10:45:05.433753Z', necessaryStock: 260, hasSufficientStock: false },
        { dueDate: '2023-05-31T10:45:05.433753Z', necessaryStock: 378, hasSufficientStock: true },
        { dueDate: '2023-06-31T10:45:05.433753Z', necessaryStock: 450, hasSufficientStock: true },
        { dueDate: '2023-07-31T10:45:05.433753Z', necessaryStock: 590, hasSufficientStock: true },
        { dueDate: '2023-08-31T10:45:05.433753Z', necessaryStock: 683, hasSufficientStock: true },
      ],
    }
  ],
};

export const normalizedProductDetailMock: NormalizedProductDetail = {
  baseProductNo: '123',
  name: 'some product',
  description: 'some product',
  usps: undefined,
  price: 9.99,
  currency: Currency.euro,
  images: [{ uri: '/foo', mediaType: 'image' }],
  referencePrice: 9.90,
  priceSaving: 0.99,
  originalPrice: undefined,
  originalPriceSaving: undefined,
  basePrice: undefined,
  brand: 'brand',
  itemSource: ItemSource.REGULAR,
  salesdrivers: [],
  status: ProductStatus.Sellable,
  freeShipping: false,
  isSellable: false,
  dimensions:undefined,
  linkedSubscriptionProduct:undefined,
};

export const progressiveDiscounts: ProgressiveDiscount[] = [
  {
    minOrderValue: 50,
    discountValue: 5
  },
  {
    minOrderValue: 100,
    discountValue: 15
  },
  {
    minOrderValue: 200,
    discountValue: 25
  }
];

export const progressiveDiscountInfo: ProgressiveDiscountInfo = {
  discounts: progressiveDiscounts,
  selectedDiscount: 0,
  remainingAmountValue: 18.02
};

export const voucherShortTypeMock: VoucherShortType = {
  code: 'PRGRSV30',
  value: 15,
  type: VirtualVoucherType.value,
  restrictions: [],
  loading: false,
};

export const reklaObjectMock: ReklaOrder = {
  externalOrderId: '1708934010',
  paymentMethod: PaymentMethod.invoice,
  deliveryAddress: PostalDeliveryAddressExample,
  externalCustomerId: '6',
  item: {
    sku: '335683',
    baseProductNumber: '335683',
    total: 29.99,
    brand: 'Das blaue Wunder',
    title: 'Fugenreiniger mit Sprühflasche',
    singlePrice: 29.99,
    priceDate: '2024-01-18T09:48:09.037002Z',
    quantity: 1,
    variants: [],
    mediaUris: [
      {
        uri: '/335683/127920_e1e658cc-8ac1-49ac-8fbb-2ef3fc2bc9e7',
        mediaType: 'image'
      }
    ],
    id: 'e598db25-a01e-4449-a338-783d77529d4d',
    deliveryAddress: PostalDeliveryAddressExample,
    shopOrderId: 'LLkKFEYrIM',
    voucherDiscountValue: 0,
    createdAt: '2024-01-18T09:48:09.037002Z',
    salesOffice: '1001'
  }
};

export const testOrderItemWithSubscription: {
  item: OrderItem;
  subscribableOrderLineItemsDetails: { [key: string]: ProductDetails };
} = {
  item: { ...testOrderItem, id:'121212325', baseProductNo: '328574', variant: { ...testOrderItem.variant, sku : '328574001' } },
  subscribableOrderLineItemsDetails: {
    '328574' : {
      ...productWithSubscriptionSellableStatusMock
    }
  }
};

export const mockStateWithLinkedSubscribableOrderItems: RootStateType = {
  ...mockState, order : {
    ...mockState.order,
    orderEntry : {
      ...mockState.order.orderEntry,
      orderLineItems : [testOrderItemWithSubscription.item],
      subscribableOrderLineItemsDetails : testOrderItemWithSubscription.subscribableOrderLineItemsDetails
    }
  }
};

export const mockSearchSuggestionsResponse: SearchSuggestionResponse[] = [
  {
    name: 'EBI BIO BackPack Brot 3x 250 g z.W..',
    type: SuggestionType.PRODUCT,
    searchParams:{
      channel:CountryCode.de,
    },
    attributes: { 
      masterId: '468461' 
    }
  }
];

const hits = camelizeKeys([
  {
    base_product_no: '448903',
    brand: {
      long_name: 'Dr. Fuchs',
      short_name: 'Dr. Fuchs'
    },
    category_path: '',
    product_line: 'Dr. Fuchs Hyaluron Therapy',
    product_name: {
      long_name: 'Hyaluron 24h Gesichtscreme',
      short_name: 'Hyaluron 24h Gesichtscreme'
    },
    variant: {
      sku: '448903',
      current_price: {
        value: 29.99,
        reference: {
          saving_percent: 33,
          value: 44.99
        },
        base: {
          amount: 1,
          unit: 'l',
          value: 199.933
        },
        is_reduced: true
      },
      color_families: [],
      colors: [],
      alloys: [],
      price_labels: [
        'Angebot des Tages'
      ],
      sizes: [],
      image: {
        uri: '/448903/59583629_67beb1f2-8efc-11ec-945c-5a72d431453d',
        height: 2600,
        width: 2080,
        image_format: 'jpg'
      },
      in_stock: true
    },
    display_price: {
      is_starting_price: false,
      value: 29.99,
      reference_price: {
        saving_percent: 33,
        value: 44.99
      },
      base: {
        amount: 1,
        unit: 'l',
        value: 199.933
      },
      is_reduced: true,
      saving_percent: 33,
      strike_price: 44.99
    },
    free_shipping: false,
    new_in_shop: false,
    in_shop_since: '2022-03-02T23:00:00Z',
    product_swatch: []
  }, 
]) as ProductVariant[];

export const rawProductSearchResponse = {
  hits: hits,
  totalHits: hits.length,
};

export const productSearchResponse: ProductSearchResponse = {
  totalProducts: 1,
  products: [
    {
      product: {
        id: '448903',
        masterValues: {
          brand: 'Dr. Fuchs',
          additionalInformation: '',
          categoryPath: [['']],
          masterProductNumber: '448903',
          freeShipping: false,
          description: 'Hyaluron 24h Gesichtscreme',
          categoryUrl: [],
          salesdrivers: [],
          brandUrl: '',
          name: 'Hyaluron 24h Gesichtscreme',
          shortDescription: 'Hyaluron 24h Gesichtscreme',
          longName: 'Hyaluron 24h Gesichtscreme',
          brandName: 'Dr. Fuchs',
        },
        variantValues: [
          {
            basePrice: 199.933,
            basePriceAmount: 1,
            basePriceUnit: 'l',
            imageURL: '/448903/59583629_67beb1f2-8efc-11ec-945c-5a72d431453d',
            price: 29.99,
            priceLabel: 'Angebot des Tages',
            productNumber: '448903',
            referencePrice: 44.99,
            referencePriceLabel: '',
            referencePriceSaving: 33,
            referencePriceSavingPercent: 33,
            referencePriceType: '',
            status: ProductStatus.Sellable,
            colorFamilies: [],
            sIZE: [],
            cOLOR: [],
            tASTE: [],
            aLLOY: [],
            mOTIV: [],
            aROMA: [],
            aMOUNT: [],
            aTONE: [],
          },
        ],
      },
      variant: {
        basePrice: 199.933,
        basePriceAmount: 1,
        basePriceUnit: 'l',
        imageURL: '/448903/59583629_67beb1f2-8efc-11ec-945c-5a72d431453d',
        price: 29.99,
        priceLabel: 'Angebot des Tages',
        productNumber: '448903',
        referencePrice: 44.99,
        referencePriceLabel: '',
        referencePriceSaving: 33,
        referencePriceSavingPercent: 33,
        referencePriceType: '',
        status: ProductStatus.Sellable,
        colorFamilies: [],
        sIZE: [],
        cOLOR: [],
        tASTE: [],
        aLLOY: [],
        mOTIV: [],
        aROMA: [],
        aMOUNT: [],
        aTONE: [],
      },
    },
  ],
};
